import moment from "moment";
import React, { useEffect } from "react";
import {
  getDownLineProfitLossAPI,
  getEventProfitLossAPI,
} from "../../service/AuthService";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function DownLinePL() {
  const [payload, setPayload] = React.useState({
    startTime: moment().subtract(10, "days").unix() * 1000,
    endTime: moment().unix() * 1000,
    page: 0,
    limit: 10,
    size: 10,
  });
  const navigate = useNavigate();
  const { userId, sport, marketId, eventId, userType } = useParams();
  const [data, setData] = React.useState([]);
  const getDownlineProfitLoss = async () => {
    const { response } = await getDownLineProfitLossAPI({
      startTime: moment(payload.startTime).unix() * 1000,
      endTime: moment(payload.endTime).unix() * 1000,
      userId,
    });
    setData(response?.filter((item) => item.userType !== "CONTROLLER"));
  };
  const getProfitLoss = async () => {
    let payload_ = {
      startTime: new Date(payload?.startTime).toISOString(),
      endTime: new Date(payload?.endTime).toISOString(),
      userId,
      page: payload.page,
      limit: payload.limit,
      size: payload.size,
    };
    if (sport) {
      payload_.sport = sport;
    }
    if (eventId) {
      payload_.eventId = eventId;
    }
    if (marketId) {
      payload_.marketId = marketId;
    }

    const { response, message, code } = await getEventProfitLossAPI(payload_);
    if (code == 200) {
      setData(response);
    } else {
      toast({
        title: "Error",
        description: message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    if (userType) {
      getDownlineProfitLoss();
    } else if (userId) {
      getProfitLoss();
    } else {
      getDownlineProfitLoss();
    }
  }, []);
  return (
    <div _ngcontent-ng-c3796931036="" className="main">
      <div _ngcontent-ng-c3796931036="" className="container-fluid">
        <router-outlet _ngcontent-ng-c3796931036="" />
        <app-downline-profit-loss
          _nghost-ng-c2464234870=""
          className="ng-star-inserted"
        >
          <div _ngcontent-ng-c2464234870="" className="animated fadeIn">
            <div _ngcontent-ng-c81861967="" className="row">
              <div _ngcontent-ng-c81861967="" className="col-sm-12 col-md-12">
                <div _ngcontent-ng-c81861967="" className="card card-report">
                  <div _ngcontent-ng-c81861967="" className="card-body row p-0">
                    <div
                      _ngcontent-ng-c81861967=""
                      className="col-lg-2 col-md-4 col-sm-8"
                    >
                      <div _ngcontent-ng-c81861967="" className="form-group">
                        <label _ngcontent-ng-c81861967="" htmlFor="dataSource">
                          Data Source
                        </label>
                        <select
                          _ngcontent-ng-c81861967=""
                          id="dataSource"
                          className="form-control form-select ng-untouched ng-pristine ng-valid"
                        >
                          <option
                            _ngcontent-ng-c81861967=""
                            disabled=""
                            value=""
                          >
                            Data Source
                          </option>
                          <option _ngcontent-ng-c81861967="" value="LIVE">
                            LIVE DATA
                          </option>
                          <option _ngcontent-ng-c81861967="" value="BACKUP">
                            BACKUP DATA
                          </option>
                          <option _ngcontent-ng-c81861967="" value="OLD">
                            OLD DATA
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c81861967=""
                      className="col-lg-2 col-md-4 col-sm-12"
                    >
                      <div
                        _ngcontent-ng-c81861967=""
                        className="form-group mb-0"
                      >
                        <div
                          _ngcontent-ng-c81861967=""
                          htmlFor="startDate"
                          className="mb-2"
                        >
                          From
                        </div>
                        <div _ngcontent-ng-c81861967="" className="input-group">
                          <input
                            _ngcontent-ng-c81861967=""
                            placeholder="Select Date"
                            name="mydate"
                            type="date"
                            readOnly=""
                            autoComplete="off"
                            onChange={(e) =>
                              setPayload({
                                ...payload,
                                startTime: moment(e.target.value).unix() * 1000,
                              })
                            }
                            value={moment(payload.startTime).format(
                              "YYYY-MM-DD"
                            )}
                            className="form-control dateClass ng-untouched ng-pristine ng-valid"
                            style={{ padding: "0px !important" }}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c81861967=""
                      className="col-lg-2 col-md-4 col-sm-12"
                    >
                      <div
                        _ngcontent-ng-c81861967=""
                        className="form-group mb-0"
                      >
                        <div
                          _ngcontent-ng-c81861967=""
                          htmlFor="endDate"
                          className="mb-2"
                        >
                          To
                        </div>
                        <input
                          _ngcontent-ng-c81861967=""
                          placeholder="Select Date"
                          name="mydate"
                          type="date"
                          readOnly=""
                          autoComplete="off"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              endTime: moment(e.target.value).unix() * 1000,
                            })
                          }
                          value={moment(payload.endTime).format("YYYY-MM-DD")}
                          className="form-control dateClass ng-untouched ng-pristine ng-valid"
                          style={{ padding: "0px !important" }}
                        ></input>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c81861967=""
                      className="col-lg-2 col-md-4 col-6"
                    >
                      <div _ngcontent-ng-c81861967="" className="form-group">
                        <div
                          _ngcontent-ng-c81861967=""
                          htmlFor="type"
                          className="mobile-d-none"
                          style={{ width: "100%" }}
                        >
                          &nbsp;
                        </div>
                        <button
                          onClick={getDownlineProfitLoss}
                          _ngcontent-ng-c81861967=""
                          type="button"
                          className="btn btn-primary btsmargin"
                        >
                          <strong _ngcontent-ng-c81861967="">
                            Get P&amp;L
                          </strong>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-ng-c2464234870="" className="row">
              <div _ngcontent-ng-c2464234870="" className="col-sm-12 col-md-12">
                <div
                  _ngcontent-ng-c2464234870=""
                  className="card card-accent-primary"
                >
                  <div
                    _ngcontent-ng-c2464234870=""
                    className="card-header account-detail-head"
                  >
                    {" "}
                    Profit Loss
                  </div>
                  <div
                    _ngcontent-ng-c2464234870=""
                    className="card-body account-stat-body"
                  >
                    <div _ngcontent-ng-c2464234870="" className="row">
                      <div _ngcontent-ng-c2464234870="" className="col-md-12">
                        <div
                          _ngcontent-ng-c2464234870=""
                          className="table-responsive"
                        >
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <div
                              className="dataTables_length"
                              id="DataTables_Table_0_length"
                            >
                              <label>
                                Show{" "}
                                <select
                                  name="DataTables_Table_0_length"
                                  aria-controls="DataTables_Table_0"
                                  className=""
                                  onChange={(e) =>
                                    setPayload({
                                      ...payload,
                                      size: e.target.value,
                                    })
                                  }
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>{" "}
                                entries
                              </label>
                            </div>
                            <div
                              id="DataTables_Table_0_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                Search:
                                <input
                                  type="search"
                                  className=""
                                  placeholder=""
                                  aria-controls="DataTables_Table_0"
                                />
                              </label>
                            </div>
                            <table
                              _ngcontent-ng-c2464234870=""
                              datatable=""
                              role="table"
                              aria-busy="false"
                              aria-colcount={6}
                              className="table table-bordered table-striped dataTable no-footer"
                              id="DataTables_Table_0"
                              aria-describedby="DataTables_Table_0_info"
                            >
                              <thead
                                _ngcontent-ng-c2464234870=""
                                role="rowgroup"
                              >
                                <tr _ngcontent-ng-c2464234870="" role="row">
                                  <th
                                    _ngcontent-ng-c2464234870=""
                                    className="text-center sorting sorting_desc"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_0"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-sort="descending"
                                    aria-label="User Name: activate to sort column ascending"
                                  >
                                    User Name
                                  </th>
                                  <th
                                    _ngcontent-ng-c2464234870=""
                                    className="text-center sorting"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_0"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Profit/Loss: activate to sort column ascending"
                                  >
                                    Profit/Loss
                                  </th>
                                  <th
                                    _ngcontent-ng-c2464234870=""
                                    className="text-center sorting_disabled"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Downline Profit/Loss"
                                  >
                                    Downline Profit/Loss
                                  </th>
                                  <th
                                    _ngcontent-ng-c2464234870=""
                                    className="text-center sorting"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_0"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Commission: activate to sort column ascending"
                                  >
                                    Commission
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="odd">
                                  <td
                                    valign="top"
                                    colSpan={4}
                                    className="dataTables_empty"
                                  >
                                    No matching records found
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                {data?.map((item) => (
                                  <tr
                                    _ngcontent-ng-c2464234870=""
                                    className="ng-star-inserted"
                                  >
                                    <td
                                      _ngcontent-ng-c2464234870=""
                                      className="text-center"
                                    >
                                      <a
                                        _ngcontent-ng-c2464234870=""
                                        onClick={() => {
                                          if (item.userType != "CLIENT") {
                                            window.location.href = `/reports/downline-pl/${item.userId}/${item.userType}`;
                                          } else {
                                            navigate(
                                              `/reports/userwise/profit-loss/`,
                                              {
                                                state: {
                                                  uid: item.userId,
                                                  userType: item.userType,
                                                },
                                              }
                                            );
                                          }
                                        }}
                                        className={"plLink"}
                                        style={{ textTransform: "uppercase" }}
                                      >
                                        {item.userName
                                          ? item?.userName
                                          : item?.sport}
                                      </a>
                                    </td>
                                    <td
                                      _ngcontent-ng-c2464234870=""
                                      className={`text-center ${
                                        (item.profitLoss < 0
                                          ? Math.abs(item.profitLoss)
                                          : -item?.profitLoss) +
                                          item?.commission >
                                        0
                                          ? "cl-green"
                                          : "cl-red"
                                      }`}
                                    >
                                      <b _ngcontent-ng-c2464234870="">
                                        {(
                                          (item.profitLoss < 0
                                            ? Math.abs(item.profitLoss)
                                            : -item?.profitLoss) +
                                          item?.commission
                                        ).toFixed(2)}
                                      </b>
                                    </td>
                                    <td
                                      _ngcontent-ng-c2464234870=""
                                      className={`text-center ${
                                        item.profitLoss > 0
                                          ? "cl-green"
                                          : "cl-red"
                                      }`}
                                    >
                                      <b _ngcontent-ng-c2464234870="">
                                        {item?.profitLoss.toFixed(2)}
                                      </b>
                                    </td>
                                    <td
                                      _ngcontent-ng-c2464234870=""
                                      className="text-center"
                                    >
                                      {" "}
                                      {item?.commission.toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                                <tr
                                  _ngcontent-ng-c2464234870=""
                                  className="ng-star-inserted"
                                >
                                  <td
                                    _ngcontent-ng-c2464234870=""
                                    className="text-center"
                                  >
                                    <a
                                      _ngcontent-ng-c2464234870=""
                                      className="plLink"
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      Total
                                    </a>
                                  </td>
                                  <td
                                    _ngcontent-ng-c2464234870=""
                                    className={`text-center ${
                                      data?.reduce((acc, item) => {
                                        return (
                                          acc +
                                          (item.profitLoss < 0
                                            ? Math.abs(item.profitLoss)
                                            : -item?.profitLoss) +
                                          item?.commission
                                        );
                                      }, 0) > 0
                                        ? "cl-green"
                                        : "cl-red"
                                    }`}
                                  >
                                    <b _ngcontent-ng-c2464234870="">
                                      {data
                                        ?.reduce((acc, item) => {
                                          return (
                                            acc +
                                            (item.profitLoss < 0
                                              ? Math.abs(item.profitLoss)
                                              : -item?.profitLoss) +
                                            item?.commission
                                          );
                                        }, 0)
                                        .toFixed(2)}
                                    </b>
                                  </td>
                                  <td
                                    _ngcontent-ng-c2464234870=""
                                    className={`text-center ${
                                      data?.reduce(
                                        (acc, item) => acc + item.profitLoss,
                                        0
                                      ) > 0
                                        ? "cl-green"
                                        : "cl-red"
                                    }`}
                                  >
                                    <b _ngcontent-ng-c2464234870="">
                                      {data
                                        ?.reduce(
                                          (acc, item) => acc + item.profitLoss,
                                          0
                                        )
                                        .toFixed(2)}{" "}
                                    </b>
                                  </td>
                                  <td
                                    _ngcontent-ng-c2464234870=""
                                    className="text-center"
                                  >
                                    {" "  }
                                    {data
                                      ?.reduce(
                                        (acc, item) => acc + item.commission,
                                        0
                                      )
                                      .toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_0_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {data.length} to {data.length} of{" "}
                              {data.length} entries
                            </div>
                            <div
                              className="dataTables_paginate paging_full_numbers"
                              id="DataTables_Table_0_paginate"
                            >
                              <a
                                className="paginate_button first disabled"
                                aria-controls="DataTables_Table_0"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="first"
                                tabIndex={-1}
                                id="DataTables_Table_0_first"
                                onClick={() =>
                                  setPayload({
                                    ...payload,
                                    page: 0,
                                  })
                                }
                              >
                                First
                              </a>
                              <a
                                className="paginate_button previous disabled"
                                aria-controls="DataTables_Table_0"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="previous"
                                tabIndex={-1}
                                id="DataTables_Table_0_previous"
                                onClick={() =>
                                  setPayload({
                                    ...payload,
                                    page: payload.page - 1,
                                  })
                                }
                              >
                                Previous
                              </a>
                              <span>
                                <a
                                  className="paginate_button current"
                                  aria-controls="DataTables_Table_0"
                                  role="link"
                                  aria-current="page"
                                  data-dt-idx={0}
                                  tabIndex={0}
                                  onClick={() =>
                                    setPayload({
                                      ...payload,
                                      page: payload.page,
                                    })
                                  }
                                >
                                  {payload.page + 1}
                                </a>
                              </span>
                              <a
                                className="paginate_button next disabled"
                                aria-controls="DataTables_Table_0"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="next"
                                tabIndex={-1}
                                id="DataTables_Table_0_next"
                                onClick={() =>
                                  setPayload({
                                    ...payload,
                                    page: payload.page + 1,
                                  })
                                }
                              >
                                Next
                              </a>
                              <a
                                className="paginate_button last disabled"
                                aria-controls="DataTables_Table_0"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="last"
                                tabIndex={-1}
                                id="DataTables_Table_0_last"
                                onClick={() =>
                                  setPayload({
                                    ...payload,
                                    page: payload.page + 1,
                                  })
                                }
                              >
                                Last
                              </a>
                            </div>
                          </div>
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-downline-profit-loss>
        {/**/}
      </div>
    </div>
  );
}
