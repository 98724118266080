import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import {
  ChangeClientGlobalSettingAPI,
  ChangeClientLimitAPI,
  ChangeClientPasswordAPI,
  ChangeClientSportStatusAPI,
  ChangeClientStatusAPI,
  ClientWithdrawAndDepositAPI,
  getClientAPI,
  getGlobalSettingsAPI,
  searchChildrenAPI,
} from "../../service/UserService";
import { Encrypt, ROLE_MAPPING } from "../../utils/constants";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getExposureTableAPI,
  getUserBetsAPI,
  handleDeleteRestoreUserAPI,
} from "../../service/AuthService";
import { usePDF } from "react-to-pdf";
import ExportCSV from "../Game/components/ExportToCsv";
import AddClient from "./Add";
import { getClientBetsCricketAPI } from "../../service/cricket";
import {
  getClientBetsSoccerAPI,
  getClientBetsTennisAPI,
} from "../../service/soccer&tennis";
import moment from "moment/moment";
export default function ListClient({ activeuser }) {
  const { id, username, userType } = useParams();
  const [clientData, setClientData] = useState([]);
  const [globalSetting, setGlobalSetting] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalAction_, setModalAction] = useState("");
  const [payload, setPayload] = useState({
    showMasterPassword: false,
    showPassword: false,
  });
  const [isBalanceOpen, setIsBalanceOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [showUserBets, setShowUserBets] = useState(false);
  const [userBets, setUserBets] = useState([]);
  const [isActiveUser, setIsActiveUser] = useState(activeuser);
  const { toPDF, targetRef } = usePDF({ filename: "clients.pdf" });
  const [currentTab, setCurrentTab] = useState("active");
  const [summary, setSummary] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const [currentClient, setCurrentClient] = useState({});
  const [marketDetails, setMarketDetails] = useState(false);
  const userMapping = {
    SUPER_ADMIN: "ADMIN",
    ADMIN: "SUPER",
    SUPER: "MASTER",
    MASTER: "AGENT",
    AGENT: "CLIENT",
  };
  const getClient = async () => {
    const { response } = await getClientAPI(
      search,
      currentPage,
      size,
      username
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS =
        response_.childrenUserResponseDTOSPage.content.sort(
          (a, b) => a.uid - b.uid
        );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    setSummary(response_);
    if (userType?.toLowerCase() == "client") {
      setClientData(
        response_.childrenUserResponseDTOSPage?.content.filter(
          (item) => item.userType?.toLowerCase() == "client"
        )
      );
    } else if (userType && username) {
      setClientData(response_.childrenUserResponseDTOSPage?.content);
    } else {
      setClientData(
        response_.childrenUserResponseDTOSPage?.content.filter(
          (item) => item.userType?.toLowerCase() != "client"
        )
      );
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const getGlobalSetting = async () => {
    try {
      const { response } = await getGlobalSettingsAPI();
      setGlobalSetting(response);
    } catch {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      window.localStorage.clear();
      window.location.href = "/";
    }
  };
  useEffect(() => {
    if (search?.length > 3) {
      getClient();
    }
  }, [search]);
  useEffect(() => {
    getClient();
    getGlobalSetting();
  }, [currentPage, totalPages, totalElements, id, size, currentTab]);
  useEffect(() => {
    setIsActiveUser(activeuser);
  }, [activeuser]);
  const internalSearch = (e) => {
    if (e.target.value.length == 0) {
      getClient();
    }
    if (e.target.value.length > 3) {
      let response_ = clientData.childrenUserResponseDTOS.filter((item) => {
        return (
          item.uid == e.target.value ||
          item?.username
            ?.toLowerCase()
            .includes(e?.target?.value.toLowerCase()) ||
          item?.name?.toLowerCase().includes(e?.target?.value.toLowerCase())
        );
      });
      setClientData({ ...clientData, childrenUserResponseDTOS: response_ });
    }
  };
  const handleModalOpen = (id, user) => {
    let payload_ = { ...payload, id, user: { ...user, ...globalSetting } };
    setPayload(payload_);
    setModalAction(id);
    onOpen();
  };
  const getUserBets = async () => {
    const { response } = await getExposureTableAPI(showUserBets);
    setUserBets(response);
  };
  useEffect(() => {
    if (showUserBets) {
      getUserBets();
    }
  }, [showUserBets]);

  const handleModalSubmit = async (action, sport = false) => {
    let modalAction = action;
    if (
      (payload.transactionPassword == "" ||
        !payload.transactionPassword ||
        payload.transactionPassword.length < 6) &&
      modalAction != "Global Setting"
    ) {
      toast.error("Transaction Password is not valid");
      return;
    }
    let isRequestFailed = false;
    let failMessage = null;
    if (modalAction === "status") {
      if (modalAction === "status" && sport) {
        const { response, code, status } = await ChangeClientSportStatusAPI(
          currentClient.uid,
          payload,
          payload.transactionPassword
        );
        if (code == 200) {
          toast.success(status);
          setPayload({});
          onClose();
          window.location.reload();
        } else {
          isRequestFailed = true;
          if (response) failMessage = response;
        }
      } else {
        const { response, code, status } = await ChangeClientStatusAPI(
          currentClient.uid,
          payload.status
            ? payload.status == "active"
              ? true
              : false
            : currentClient.isActive,
          payload.isBetActive
            ? !payload.isBetActive
            : currentClient.isBetLocked,
          payload.transactionPassword
        );
        if (code == 200) {
          toast.success(status);
          setPayload({});
          onClose();
          window.location.reload();
        } else {
          isRequestFailed = true;
          if (response) failMessage = response;
        }
      }
    }
    if (modalAction == "exposureLimit" || modalAction == "creditLimit") {
      const { response, code, status } = await ChangeClientLimitAPI(
        {
          newLimit: payload.newLimit,
          transactionPassword: payload.transactionPassword,
          oldLimit: payload.oldLimit,
          limitType:
            modalAction == "exposureLimit"
              ? "EXPOSURE_LIMIT"
              : "CREDIT_REFERENCE",
        },
        currentClient.uid
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        window.location.reload();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (modalAction == "changePassword") {
      if (payload.newPassword !== payload.confirmPassword)
        return toast.error("Password and Confirm Password must be same");
      if (payload?.newPassword.length < 5)
        return toast.error("Password must be at least 5 characters long ");
      const { response, code, status } = await ChangeClientPasswordAPI(
        {
          newPassword: Encrypt(payload.newPassword),
          transactionPassword: payload.transactionPassword,
          resetRequired: true,
        },
        currentClient.uid
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        window.location.reload();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (modalAction == "globalSettings") {
      if (
        payload.user.transactionPassword == "" ||
        !payload.user.transactionPassword ||
        payload.user.transactionPassword.length < 6
      ) {
        toast.error("Transaction Password is not valid");
        return;
      }
      let payload_ = payload;
      delete payload_.user.id;
      const { response, code, status } = await ChangeClientGlobalSettingAPI(
        payload_.user,
        currentClient.uid
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        window.location.reload();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (modalAction == "deposit" || modalAction == "withdraw") {
      const { response, code, status } = await ClientWithdrawAndDepositAPI(
        currentClient.uid,
        {
          balance: payload.amount,
          transactionPassword: payload.transactionPassword,
          remarks: payload.remarks,
          transactionType: modalAction == "deposit" ? "DEPOSIT" : "WITHDRAW",
        }
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        window.location.reload();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (isRequestFailed && failMessage.message) {
      toast.error(
        `Something went wrong while processing ${modalAction} request . Please check your inputs and try again`
      );
    } else if (isRequestFailed) {
      toast.error(failMessage);
    }
    await getClient();
  };
  const handleSearch = async (e) => {
    let searchText = e.target.value;
    e.preventDefault();
    if (searchText == "" || searchText.length < 3) {
      getClient();
      return;
    }
    const { response, code, status } = await searchChildrenAPI(searchText);
    if (code == 200) {
      setClientData(response);
    } else {
      getClient();
    }
  };
  const navigateToAdd = () => {
    navigate("/admin/manage-client/add", {
      state: {
        id: id,
      },
    });
  };
  const navigateInner = (client) => {
    if (client.userType != "CLIENT") {
      navigate(
        `/admin/manage-client/list/${client.userType}/${client.username}/${client?.uid}`
      );
    }
  };
  const handleDelete = async (item) => {
    const { response, code, status } = await handleDeleteRestoreUserAPI(
      item?.uid,
      true
    );
    if (code == 200) {
      toast.success(status);
      setPayload({});
      onClose();
      window.location.reload();
    }
  };
  const getBetByEventsId = async (item) => {
    if (item?.eventType == "CRICKET") {
      const { response } = await getClientBetsCricketAPI(
        item?.eventId,
        "MATCHED"
      );
      setMarketDetails({ ...item, bets: response });
    } else if (item?.eventType == "SOCCER") {
      const { response } = await getClientBetsSoccerAPI(
        item?.eventId,
        "MATCHED"
      );
      setMarketDetails({ ...item, bets: response });
    } else if (item?.eventType == "TENNIS") {
      const { response } = await getClientBetsTennisAPI(
        item?.eventId,
        "MATCHED"
      );
      setMarketDetails({ ...item, bets: response });
    }
  };
  const haveButtonPermission =
    userData?.userType == "SUPER_ADMIN" || "TECH_ADMIN";

  return (
    <div _ngcontent-ng-c3796931036="" className="main">
      <div _ngcontent-ng-c3796931036="" className="container-fluid">
        <router-outlet _ngcontent-ng-c3796931036="" />
        <app-user-list _nghost-ng-c1207450300="" className="ng-star-inserted">
          <div _ngcontent-ng-c1207450300="" className="animated fadeIn">
            <div _ngcontent-ng-c1207450300="" className="total_all">
              <a
                _ngcontent-ng-c1207450300=""
                id="refresh"
                className="btn_replay"
              >
                <img
                  _ngcontent-ng-c1207450300=""
                  src="https://ag.555royal.in/assets/transparent-login.gif"
                />
              </a>
              <a
                _ngcontent-ng-c1207450300=""
                data-bs-toggle="modal"
                data-bs-target="#AddNewUserModal"
                className="add_member mb-10"
              >
                <img
                  _ngcontent-ng-c1207450300=""
                  src="https://ag.555royal.in/assets/transparent-login.gif"
                />
                Add User{" "}
              </a>
            </div>
            <div
              _ngcontent-ng-c1207450300=""
              id="totalBox"
              className="total_box"
            >
              <dl _ngcontent-ng-c1207450300="" className="total_dl">
                <dt _ngcontent-ng-c1207450300="">Total Balance</dt>
                <dd _ngcontent-ng-c1207450300="" id="totalBalance">
                  BAL. {summary?.summary2?.totalBalance?.toFixed(2)}
                </dd>
              </dl>
              <dl _ngcontent-ng-c1207450300="" className="total_dl">
                <dt _ngcontent-ng-c1207450300="">Total Exposure</dt>
                <dd _ngcontent-ng-c1207450300="" id="totalExposure">
                  BAL.{" "}
                  <span _ngcontent-ng-c1207450300="" className="cl-red">
                    {summary?.summary2?.totalExposure?.toFixed(2)}
                  </span>
                </dd>
              </dl>
              <dl _ngcontent-ng-c1207450300="" className="total_dl">
                <dt _ngcontent-ng-c1207450300="">Available Balance</dt>
                <dd _ngcontent-ng-c1207450300="" id="totalAvailBal">
                  BAL.{" "}
                  <span _ngcontent-ng-c1207450300="">
                    {summary?.summary2?.availableBalance?.toFixed(2)}
                  </span>
                </dd>
              </dl>
              <dl _ngcontent-ng-c1207450300="" className="total_dl">
                <dt _ngcontent-ng-c1207450300="">Balance</dt>
                <dd _ngcontent-ng-c1207450300="" id="mastersBalance">
                  BAL. {summary?.summary2?.balance?.toFixed(2)}
                </dd>
              </dl>
              <dl _ngcontent-ng-c1207450300="" className="total_dl">
                <dt _ngcontent-ng-c1207450300="">Total Avail. bal.</dt>
                <dd _ngcontent-ng-c1207450300="" id="mastersAvailBal">
                  BAL. {summary?.summary2?.totalAvailableBalance?.toFixed(2)}
                </dd>
              </dl>
              <dl _ngcontent-ng-c1207450300="" className="total_dl">
                <dt _ngcontent-ng-c1207450300="">Upline P/L</dt>
                <dd _ngcontent-ng-c1207450300="" id="mastersAvailBal">
                  {" "}
                  BAL.{" "}
                  <span _ngcontent-ng-c1207450300="" className="cl-red">
                    {" "}
                    {summary?.summary2?.uplinePl?.toFixed(2)}
                  </span>
                </dd>
              </dl>
            </div>
            <div _ngcontent-ng-c1207450300="" className="row">
              <div _ngcontent-ng-c1207450300="" className="col-md-12">
                <div
                  _ngcontent-ng-c1207450300=""
                  className="card my-exchange-head"
                >
                  <div _ngcontent-ng-c1207450300="" className="card-body">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="table-responsive"
                    >
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <div
                          className="dataTables_length"
                          id="DataTables_Table_0_length"
                        >
                          <label>
                            Show{" "}
                            <select
                              name="DataTables_Table_0_length"
                              aria-controls="DataTables_Table_0"
                              className=""
                              onChange={(e) => setSize(e.target.value)}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entries
                          </label>
                        </div>
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            Search:
                            <input
                              type="search"
                              className=""
                              onChange={(e) => setSearch(e.target.value)}
                              placeholder=""
                              value={search}
                              aria-controls="DataTables_Table_0"
                            />
                          </label>
                        </div>
                        <table
                          _ngcontent-ng-c1207450300=""
                          datatable=""
                          role="table"
                          aria-busy="false"
                          aria-colcount={6}
                          className="table table-bordered table-hover dataTable no-footer"
                          id="DataTables_Table_0"
                          aria-describedby="DataTables_Table_0_info"
                        >
                          <thead _ngcontent-ng-c1207450300="" role="rowgroup">
                            <tr _ngcontent-ng-c1207450300="" role="row">
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="sorting sorting_asc"
                                tabIndex={0}
                                aria-controls="DataTables_Table_0"
                                rowSpan={1}
                                colSpan={1}
                                aria-sort="ascending"
                                aria-label="Username: activate to sort column descending"
                              >
                                Username
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="sorting"
                                tabIndex={0}
                                aria-controls="DataTables_Table_0"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Credit Ref.: activate to sort column ascending"
                              >
                                Credit Ref.
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="sorting"
                                tabIndex={0}
                                aria-controls="DataTables_Table_0"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Balance: activate to sort column ascending"
                              >
                                Balance
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="sorting"
                                tabIndex={0}
                                aria-controls="DataTables_Table_0"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Exposure: activate to sort column ascending"
                              >
                                Exposure
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="sorting"
                                tabIndex={0}
                                aria-controls="DataTables_Table_0"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Exposure Limit: activate to sort column ascending"
                              >
                                Exposure Limit
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Avail.Bal."
                              >
                                Avail.Bal.
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Ref. P/L"
                              >
                                Ref. P/L
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                style={{ width: 75 }}
                                className="sorting"
                                tabIndex={0}
                                aria-controls="DataTables_Table_0"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Partnership: activate to sort column ascending"
                              >
                                Partnership
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="sorting"
                                tabIndex={0}
                                aria-controls="DataTables_Table_0"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                              >
                                Status
                              </th>
                              <th
                                _ngcontent-ng-c1207450300=""
                                className="minSettings sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Actions"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="odd">
                              <td
                                valign="top"
                                colSpan={10}
                                className="dataTables_empty"
                              >
                                No matching records found
                              </td>
                            </tr>
                          </tbody>
                          <tbody
                            _ngcontent-ng-c1207450300=""
                            className="ng-star-inserted"
                          >
                            {clientData?.map((item) => (
                              <tr
                                _ngcontent-ng-c1207450300=""
                                className="ng-star-inserted"
                              >
                                <td
                                  onClick={() => navigateInner(item)}
                                  _ngcontent-ng-c1207450300=""
                                >
                                  <span
                                    _ngcontent-ng-c1207450300=""
                                    className="badge badge-success"
                                  >
                                    {item?.userType == "CLIENT"
                                      ? "USER"
                                      : item?.userType}
                                  </span>
                                  <span
                                    _ngcontent-ng-c1207450300=""
                                    title="testun"
                                  >
                                    {" "}
                                    {item?.username}
                                  </span>
                                </td>
                                <td _ngcontent-ng-c1207450300="">
                                  <strong _ngcontent-ng-c1207450300="">
                                    {item?.creditReference}
                                  </strong>
                                  <a
                                    _ngcontent-ng-c1207450300=""
                                    data-bs-toggle="modal"
                                    onClick={() => {
                                      setCurrentClient(item);
                                    }}
                                    data-bs-target="#EditRefereceModalC"
                                  >
                                    {" "}
                                    &nbsp;{" "}
                                    <i
                                      _ngcontent-ng-c1207450300=""
                                      className="fa fa-pencil-square-o"
                                    />
                                  </a>
                                  <a
                                    _ngcontent-ng-c1207450300=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalCreditReference"
                                    title="History"
                                  >
                                    {" "}
                                    &nbsp;{" "}
                                    <i
                                      _ngcontent-ng-c1207450300=""
                                      className="fa fa-eye"
                                    />
                                  </a>
                                </td>
                                <td _ngcontent-ng-c1207450300="">
                                  {item?.balance?.toFixed(2)}
                                </td>
                                <td _ngcontent-ng-c1207450300="">
                                  <a
                                    onClick={() => {
                                      if (
                                        item?.userType?.toLowerCase() ==
                                        "client"
                                      ) {
                                        setShowUserBets(item?.uid);
                                        setCurrentClient(item);
                                      }
                                    }}
                                    _ngcontent-ng-c1207450300=""
                                    data-bs-toggle={
                                      item?.userType?.toLowerCase() ==
                                        "client" && "modal"
                                    }
                                    data-bs-target={
                                      item?.userType?.toLowerCase() ==
                                        "client" && "#modalExposureDetail"
                                    }
                                    className="cl-red"
                                  >
                                    <span
                                      _ngcontent-ng-c1207450300=""
                                      style={{ cursor: "pointer" }}
                                    >
                                      {" "}
                                      ({item.liability?.toFixed(2)}){" "}
                                    </span>
                                  </a>
                                </td>
                                <td
                                  _ngcontent-ng-c1207450300=""
                                  className="exp-limit"
                                >
                                  <strong _ngcontent-ng-c1207450300="">
                                    {item?.exposureLimit?.toFixed(2)}
                                  </strong>
                                  <a
                                    _ngcontent-ng-c1207450300=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#EditRefereceModal"
                                  >
                                    {" "}
                                    &nbsp;{" "}
                                    <i
                                      onClick={() => {
                                        setCurrentClient(item);
                                      }}
                                      _ngcontent-ng-c1207450300=""
                                      className="fa fa-pencil-square-o"
                                    />
                                  </a>
                                </td>
                                <td _ngcontent-ng-c1207450300="">
                                  {(item?.availableBalance).toFixed(2)}
                                </td>
                                <td _ngcontent-ng-c1207450300="">
                                  <span
                                    _ngcontent-ng-c1207450300=""
                                    className={item?.ownPl < 0 ? "cl-red" : ""}
                                  >
                                    {" "}
                                    ({parseFloat(item?.ownPl).toFixed(2)})
                                  </span>
                                </td>
                                <td _ngcontent-ng-c1207450300="">
                                  {item.partnershipInCricket}
                                </td>
                                <td _ngcontent-ng-c1207450300="">
                                  <span
                                    _ngcontent-ng-c1207450300=""
                                    className={`badge status-${
                                      item?.isActive ? "active" : "lock"
                                    }`}
                                  >
                                    {item?.isActive ? "active" : "locked"}
                                  </span>
                                </td>
                                <td
                                  _ngcontent-ng-c1207450300=""
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <ul
                                    _ngcontent-ng-c1207450300=""
                                    className="action"
                                  >
                                    <li _ngcontent-ng-c1207450300="">
                                      <a
                                        _ngcontent-ng-c1207450300=""
                                        data-bs-toggle="modal"
                                        data-bs-target="#depositWithdrawModal"
                                        title="Banking"
                                        onClick={() => {
                                          setCurrentClient(item);
                                        }}
                                        className="bank"
                                      >
                                        <svg
                                          _ngcontent-ng-c1207450300=""
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="16px"
                                          height="16px"
                                          viewBox="0 0 26 26"
                                          enableBackground="new 0 0 26 26"
                                          xmlSpace="preserve"
                                        >
                                          <g
                                            _ngcontent-ng-c1207450300=""
                                            id="Layer_1"
                                          >
                                            <g _ngcontent-ng-c1207450300="">
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M16.237,3.225c-0.717,0-1.376,0.058-2.006,0.172c1.834,0.373,3.466,1.263,4.868,2.67    c1.92,1.924,2.863,4.221,2.863,6.947c0,2.669-0.943,4.965-2.863,6.89c-1.402,1.406-3.034,2.296-4.868,2.673    c0.63,0.141,1.289,0.199,2.006,0.199c2.69,0,4.981-0.976,6.874-2.872c1.92-1.925,2.863-4.221,2.863-6.89    c0-2.727-0.943-5.023-2.863-6.947C21.218,4.172,18.927,3.225,16.237,3.225z"
                                              />
                                              <g _ngcontent-ng-c1207450300="">
                                                <path
                                                  _ngcontent-ng-c1207450300=""
                                                  fill="currentColor"
                                                  d="M2.889,6.067c-1.918,1.924-2.863,4.221-2.863,6.947c0,2.669,0.945,4.965,2.863,6.89     C4.78,21.8,7.1,22.776,9.792,22.776c2.692,0,4.984-0.976,6.875-2.872c1.915-1.925,2.864-4.221,2.864-6.89     c0-2.727-0.949-5.023-2.864-6.947c-1.891-1.895-4.183-2.842-6.875-2.842C7.1,3.225,4.78,4.172,2.889,6.067z"
                                                />
                                              </g>
                                              <g _ngcontent-ng-c1207450300="">
                                                <path
                                                  _ngcontent-ng-c1207450300=""
                                                  fill="#FFFFFF"
                                                  d="M8.819,5.607v1.579V7.76C7.902,7.875,7.186,8.192,6.728,8.651c-0.487,0.431-0.746,1.004-0.746,1.723     c-0.028,0.632,0.229,1.177,0.659,1.664c0.459,0.518,1.203,1.033,2.292,1.607c0.888,0.46,1.488,0.832,1.804,1.18     c0.344,0.342,0.487,0.745,0.487,1.145c0,0.689-0.459,1.034-1.374,1.034c-0.945,0-1.403-0.345-1.403-0.976v-0.861H5.925v0.748     c0,0.717,0.229,1.293,0.744,1.779c0.488,0.431,1.204,0.748,2.149,0.861v0.432c0,0.717,0,1.207,0,1.434h2.205v-1.52v-0.346     c0.945-0.113,1.661-0.431,2.147-0.889c0.516-0.459,0.744-1.063,0.744-1.811c0-0.633-0.199-1.204-0.657-1.693     c-0.431-0.518-1.204-1.062-2.292-1.637c-0.888-0.459-1.489-0.861-1.804-1.205c-0.344-0.315-0.488-0.66-0.488-1.033     c0-0.661,0.43-1.006,1.347-1.006c0.889,0,1.347,0.345,1.347,1.006v0.43h2.52v-0.315c0-0.717-0.229-1.32-0.716-1.78     c-0.486-0.459-1.202-0.746-2.147-0.861V7.245V5.607H8.819z"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </a>
                                    </li>
                                    <li _ngcontent-ng-c1207450300="">
                                      <a
                                        _ngcontent-ng-c1207450300=""
                                        title="Profit/Loss"
                                        className="p_l"
                                        onClick={() => {
                                          navigate("/my-account/profit-loss", {
                                            state: item,
                                          });
                                        }}
                                      >
                                        <svg
                                          _ngcontent-ng-c1207450300=""
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="16px"
                                          height="16px"
                                          viewBox="0 0 26 26"
                                          enableBackground="new 0 0 26 26"
                                          xmlSpace="preserve"
                                        >
                                          <g
                                            _ngcontent-ng-c1207450300=""
                                            id="Layer_2"
                                          >
                                            <g _ngcontent-ng-c1207450300="">
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M25.958,16.59h-4.393V3.555h-3.287V16.59h-4.391l5.971,5.855L25.958,16.59z"
                                              />
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M12.08,9.442l-5.97-5.855L0.042,9.442h4.357v13.004h3.324V9.442H12.08z"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                      </a>
                                    </li>
                                    <li _ngcontent-ng-c1207450300="">
                                      <a
                                        _ngcontent-ng-c1207450300=""
                                        title="Bet History"
                                        className="betting_history"
                                        onClick={() => {
                                          navigate("/my-account/bet-history", {
                                            state: item,
                                          });
                                        }}
                                      >
                                        <svg
                                          _ngcontent-ng-c1207450300=""
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="16px"
                                          height="16px"
                                          viewBox="0 0 26 26"
                                          enableBackground="new 0 0 26 26"
                                          xmlSpace="preserve"
                                        >
                                          <g
                                            _ngcontent-ng-c1207450300=""
                                            id="Layer_3"
                                          >
                                            <g _ngcontent-ng-c1207450300="">
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M9.793,15.429v-2.862H0.234v2.862H9.793z"
                                              />
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M0.234,6.137V9.03h25.501V6.137H0.234z"
                                              />
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M25.736,0.574H0.234v2.893h25.501V0.574z"
                                              />
                                              <g _ngcontent-ng-c1207450300="">
                                                <g _ngcontent-ng-c1207450300="">
                                                  <path
                                                    _ngcontent-ng-c1207450300=""
                                                    fill="currentColor"
                                                    d="M11.076,18.062c0,2.028,0.738,3.766,2.15,5.179c1.412,1.449,3.174,2.155,5.197,2.186      c2.054-0.03,3.751-0.736,5.194-2.186c1.443-1.413,2.149-3.15,2.149-5.179c0-2.054-0.706-3.792-2.149-5.236      c-1.443-1.416-3.141-2.123-5.194-2.123c-2.022,0-3.785,0.708-5.197,2.123C11.814,14.27,11.076,16.008,11.076,18.062z"
                                                  />
                                                </g>
                                                <g _ngcontent-ng-c1207450300="">
                                                  <polyline
                                                    _ngcontent-ng-c1207450300=""
                                                    fill="none"
                                                    stroke="#FFFFFF"
                                                    strokeWidth="1.2846"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeMiterlimit={10}
                                                    points="      21.339,20.09 18.453,19.221 18.453,14.463     "
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </a>
                                    </li>
                                    <li _ngcontent-ng-c1207450300="">
                                      <a
                                        _ngcontent-ng-c1207450300=""
                                        data-bs-toggle="modal"
                                        data-bs-target="#changeUserStatusModal"
                                        title="Setting"
                                        className="status"
                                        onClick={() => {
                                          setCurrentClient(item);
                                        }}
                                      >
                                        <svg
                                          _ngcontent-ng-c1207450300=""
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="16px"
                                          height="16px"
                                          viewBox="0 0 26 26"
                                          enableBackground="new 0 0 26 26"
                                          xmlSpace="preserve"
                                        >
                                          <g
                                            _ngcontent-ng-c1207450300=""
                                            id="Layer_7"
                                          >
                                            <g _ngcontent-ng-c1207450300="">
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M15.75,0.394H10.25v4.135C9.163,4.891,8.187,5.436,7.318,6.197L3.807,3.985L0.875,8.664l3.511,2.212    c-0.145,0.689-0.217,1.379-0.217,2.14c0,0.799,0.107,1.633,0.29,2.359L1.02,17.659l3.041,4.61l3.474-2.322    c0.833,0.688,1.737,1.158,2.715,1.485v4.175h5.501v-4.209c1.05-0.327,2.028-0.872,2.935-1.635l3.544,2.214l2.896-4.714    l-3.55-2.178c0.184-0.689,0.252-1.343,0.252-2.069c0-0.87-0.104-1.631-0.286-2.358l3.44-2.321l-3.079-4.606l-3.441,2.285    c-0.831-0.653-1.733-1.161-2.711-1.452V0.394z M7.788,13.162c0-0.072,0-0.109,0-0.145c0-1.451,0.508-2.719,1.521-3.736    c1.012-1.017,2.244-1.524,3.69-1.524c1.412,0,2.643,0.507,3.691,1.524c0.979,1.017,1.484,2.176,1.519,3.555    c0,0.036,0,0.072,0,0.145v0.036c0,1.416-0.505,2.648-1.519,3.667c-1.049,1.015-2.279,1.52-3.691,1.52    c-1.446,0-2.679-0.505-3.69-1.52C8.332,15.702,7.826,14.504,7.788,13.162z"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                      </a>
                                    </li>
                                    <li _ngcontent-ng-c1207450300="">
                                      <a
                                        _ngcontent-ng-c1207450300=""
                                        title="Profile"
                                        className="profile"
                                        onClick={() =>
                                          navigate("/my-account/profile", {
                                            state: item,
                                          })
                                        }
                                      >
                                        <svg
                                          _ngcontent-ng-c1207450300=""
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="16px"
                                          height="16px"
                                          viewBox="0 0 26 26"
                                          enableBackground="new 0 0 26 26"
                                          xmlSpace="preserve"
                                        >
                                          <g
                                            _ngcontent-ng-c1207450300=""
                                            id="Layer_6"
                                          >
                                            <g _ngcontent-ng-c1207450300="">
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M25.36,21.222c-0.678-0.975-1.612-1.868-2.842-2.634c-2.586-1.699-5.682-2.551-9.37-2.508    c-3.646-0.043-6.783,0.809-9.412,2.508c-1.4,0.894-2.46,1.955-3.095,3.104v3.91H25.36V21.222z"
                                              />
                                              <path
                                                _ngcontent-ng-c1207450300=""
                                                fill="currentColor"
                                                d="M17.176,11.024c1.23-1.233,1.822-2.678,1.822-4.421c0-1.699-0.592-3.188-1.822-4.377    c-1.187-1.232-2.671-1.827-4.367-1.827c-1.738,0-3.18,0.595-4.409,1.827C7.213,3.416,6.576,4.904,6.576,6.603    c0,1.743,0.637,3.188,1.824,4.421c1.229,1.188,2.671,1.827,4.409,1.827C14.505,12.851,15.99,12.212,17.176,11.024z"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                      </a>
                                    </li>
                                    {haveButtonPermission && (
                                      <>
                                        <li _ngcontent-ng-c1207450300="">
                                          <a
                                            _ngcontent-ng-c1207450300=""
                                            title="Sport Settings"
                                            data-bs-toggle="modal"
                                            data-bs-target="#sportSettingModal"
                                            className="profile"
                                            onClick={() => {
                                              setPayload({
                                                ...payload,
                                                isCricketLocked:
                                                  item?.isCricketLocked == null
                                                    ? false
                                                    : item?.isCricketLocked,
                                                isTennisLocked:
                                                  item?.isTennisLocked == null
                                                    ? false
                                                    : item?.isTennisLocked,
                                                isSoccerLocked:
                                                  item?.isSoccerLocked == null
                                                    ? false
                                                    : item?.isSoccerLocked,
                                                isCasinoLocked:
                                                  item?.isCasinoLocked == null
                                                    ? false
                                                    : item?.isCasinoLocked,
                                              });
                                              setCurrentClient(item);
                                            }}
                                          >
                                            <svg
                                              _ngcontent-ng-c1207450300=""
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              version="1.1"
                                              x="0px"
                                              y="0px"
                                              width="16px"
                                              height="16px"
                                              viewBox="0 0 26 26"
                                              enableBackground="new 0 0 26 26"
                                              xmlSpace="preserve"
                                            >
                                              <g
                                                _ngcontent-ng-c1207450300=""
                                                id="Layer_5"
                                              >
                                                <g _ngcontent-ng-c1207450300="">
                                                  <path
                                                    _ngcontent-ng-c1207450300=""
                                                    fill="currentColor"
                                                    d="M11.605,3.012c-0.931-0.266-2.259-0.398-3.986-0.398H0.179v20.772h7.439c1.728,0,3.056-0.131,3.986-0.4    c0.532-0.131,0.93-0.399,1.328-0.799v-8.653C12.667,13.265,12.27,13.134,11.87,13c1.994-0.534,2.924-1.995,2.924-4.527    c0-1.465-0.266-2.66-0.93-3.595C13.2,3.946,12.535,3.279,11.605,3.012L11.605,3.012z M9.479,8.872c0,0.665-0.133,1.199-0.53,1.598    c-0.268,0.399-1.063,0.665-2.526,0.665H5.227V6.742H6.69c1.195-0.134,1.991,0.133,2.259,0.531    C9.346,7.674,9.479,8.205,9.479,8.872L9.479,8.872z M5.227,14.862h1.195c1.463,0,2.259,0.27,2.526,0.8    c0.397,0.398,0.532,0.936,0.532,1.604c0,0.53-0.135,1.062-0.532,1.461c-0.268,0.399-1.063,0.669-2.259,0.669H5.227V14.862    L5.227,14.862z"
                                                  />
                                                  <path
                                                    _ngcontent-ng-c1207450300=""
                                                    fill="currentColor"
                                                    d="M23.43,13.4v-1.73c0-0.934-0.4-1.864-1.062-2.53c-0.661-0.667-1.592-1.065-2.521-1.065    c-0.938,0-1.866,0.398-2.528,1.065c-0.667,0.666-1.063,1.597-1.063,2.53v1.73h-1.33c-0.266,0-0.532,0.134-0.798,0.399    c-0.133,0.133-0.265,0.401-0.265,0.667v7.59c0,0.269,0.132,0.53,0.265,0.668c0.266,0.262,0.532,0.393,0.798,0.393h9.826    c0.276,0,0.538-0.131,0.8-0.393c0.138-0.138,0.269-0.399,0.269-0.668v-4.928l0,0v-2.662c0-0.266-0.131-0.534-0.269-0.667    c-0.262-0.266-0.523-0.399-0.8-0.399H23.43L23.43,13.4z M22.1,11.669v1.73h-4.512v-1.73c0-0.665,0.262-1.2,0.661-1.599    c0.398-0.397,0.929-0.663,1.598-0.663c0.661,0,1.191,0.266,1.591,0.663C21.831,10.469,22.1,11.004,22.1,11.669L22.1,11.669z     M20.901,18.197c0,0.399-0.124,0.661-0.523,0.93l0.131,0.931h-1.199v-0.931c-0.262-0.269-0.4-0.53-0.4-0.93    c0-0.27,0.139-0.538,0.27-0.669c0.131-0.262,0.393-0.399,0.669-0.399c0.262,0,0.53,0.138,0.661,0.399    C20.777,17.659,20.901,17.927,20.901,18.197L20.901,18.197z"
                                                  />
                                                </g>
                                              </g>
                                            </svg>
                                          </a>
                                        </li>
                                        <li _ngcontent-ng-c1207450300="">
                                          <a
                                            _ngcontent-ng-c1207450300=""
                                            title="Delete"
                                            onClick={() => handleDelete(item)}
                                            className="profile"
                                          >
                                            <svg
                                              _ngcontent-ng-c1207450300=""
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              version="1.1"
                                              x="0px"
                                              y="0px"
                                              width="16px"
                                              height="16px"
                                              viewBox="0 0 26 26"
                                              enableBackground="new 0 0 26 26"
                                              xmlSpace="preserve"
                                            >
                                              <g
                                                _ngcontent-ng-c1207450300=""
                                                id="Layer_4"
                                              >
                                                <g _ngcontent-ng-c1207450300="">
                                                  <path
                                                    _ngcontent-ng-c1207450300=""
                                                    fill="currentColor"
                                                    d="M16.162,0.213H9.838C9.358,0.535,9.005,1.049,8.78,1.821H2.233c-0.191,0-0.32,0.097-0.32,0.29v2.541    c0,0.193,0.129,0.29,0.32,0.322h21.534c0.224-0.032,0.32-0.129,0.32-0.322V2.111c0-0.193-0.097-0.29-0.32-0.29h-6.514    C16.995,1.049,16.643,0.535,16.162,0.213z"
                                                  />
                                                  <path
                                                    _ngcontent-ng-c1207450300=""
                                                    fill="currentColor"
                                                    d="M19.725,25.788c1.088-0.453,1.698-1.256,1.795-2.415c0-0.031,0-0.062,0-0.097l1.058-16.694H3.454    l1.027,16.694c0,0.035,0,0.065,0.031,0.097c0.096,1.159,0.674,1.962,1.765,2.415H19.725z"
                                                  />
                                                </g>
                                              </g>
                                            </svg>
                                          </a>
                                        </li>
                                      </>
                                    )}
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {/**/}
                          {/**/}
                        </table>

                        <div
                          className="dataTables_info"
                          id="DataTables_Table_0_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing {currentPage + 1} to {currentPage + 10} of{" "}
                          {clientData?.length} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_full_numbers"
                          id="DataTables_Table_0_paginate"
                        >
                          <a
                            className="paginate_button first disabled"
                            aria-controls="DataTables_Table_0"
                            aria-disabled="true"
                            role="link"
                            onClick={() => setCurrentPage(0)}
                            data-dt-idx="first"
                            tabIndex={-1}
                            id="DataTables_Table_0_first"
                          >
                            First
                          </a>
                          <a
                            className="paginate_button previous disabled"
                            aria-controls="DataTables_Table_0"
                            aria-disabled="true"
                            role="link"
                            data-dt-idx="previous"
                            onClick={prevPage}
                            tabIndex={-1}
                            id="DataTables_Table_0_previous"
                          >
                            Previous
                          </a>
                          <span />
                          <a
                            className="paginate_button next disabled"
                            aria-controls="DataTables_Table_0"
                            aria-disabled="true"
                            role="link"
                            onClick={nextPage}
                            data-dt-idx="next"
                            tabIndex={-1}
                            id="DataTables_Table_0_next"
                          >
                            Next
                          </a>
                          <a
                            className="paginate_button last disabled"
                            aria-controls="DataTables_Table_0"
                            aria-disabled="true"
                            role="link"
                            data-dt-idx="last"
                            onClick={() =>
                              setCurrentPage(
                                Math.floor(clientData?.length / 10)
                              )
                            }
                            tabIndex={-1}
                            id="DataTables_Table_0_last"
                          >
                            Last
                          </a>
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddClient
            id={id ? id : userData?.uid}
            userType={id ? userMapping[userType] : userType}
          />
          <div
            _ngcontent-ng-c1207450300=""
            id="EditRefereceModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            className="modal fade"
            aria-modal="true"
            role="dialog"
          >
            <div _ngcontent-ng-c1207450300="" className="modal-dialog">
              <div _ngcontent-ng-c1207450300="" className="modal-content">
                <div
                  _ngcontent-ng-c1207450300=""
                  className="modal-header bg-primary"
                >
                  <h5 _ngcontent-ng-c1207450300="" className="modal-title">
                    Edit Exposure Limit - {currentClient?.username}{" "}
                  </h5>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="close"
                  >
                    <span
                      _ngcontent-ng-c1207450300=""
                      aria-hidden="true"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </span>
                  </button>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-body">
                  <div _ngcontent-ng-c1207450300="" className="ms-3">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div _ngcontent-ng-c1207450300="" className="col-2">
                        Current
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-8">
                        {/**/}
                        <strong
                          _ngcontent-ng-c1207450300=""
                          className="ng-star-inserted"
                        >
                          {currentClient?.exposureLimit}
                        </strong>
                        {/**/}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="col-md-2 col-form-label"
                      >
                        New
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-md-8">
                        {/**/}
                        <input
                          _ngcontent-ng-c1207450300=""
                          type="number"
                          oninput="validity.valid||(value='');"
                          min={0}
                          max={999999999999}
                          value={payload?.newLimit}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              newLimit: e.target.value,
                              oldLimit: currentClient?.exposureLimit,
                            });
                          }}
                          required=""
                          name="newLimit"
                          className="text-right form-control maxlength12 ng-untouched ng-pristine ng-invalid ng-star-inserted"
                        />
                        {/**/}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="col-md-2 col-form-label"
                      >
                        Master Password
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-md-8">
                        <div
                          _ngcontent-ng-c1207450300=""
                          className="d-flex align-items-center position-relative"
                        >
                          <input
                            _ngcontent-ng-c1207450300=""
                            autoComplete="current-password"
                            id="masterpassword2"
                            name="password"
                            required=""
                            value={payload?.transactionPassword}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                transactionPassword: e.target.value,
                              });
                            }}
                            className="form-control ng-untouched ng-pristine ng-invalid"
                            type="password"
                          />
                          <a _ngcontent-ng-c1207450300="" className="eyebtn">
                            <i
                              _ngcontent-ng-c1207450300=""
                              className="far fa-eye"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-footer">
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleModalSubmit("exposureLimit");
                    }}
                  >
                    Submit
                  </button>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-default"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            _ngcontent-ng-c1207450300=""
            id="EditRefereceModalC"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c1207450300="" className="modal-dialog">
              <div _ngcontent-ng-c1207450300="" className="modal-content">
                <div
                  _ngcontent-ng-c1207450300=""
                  className="modal-header bg-primary"
                >
                  <h5 _ngcontent-ng-c1207450300="" className="modal-title">
                    Edit Credit Reference - {currentClient?.username}
                  </h5>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="close"
                  >
                    <span
                      _ngcontent-ng-c1207450300=""
                      aria-hidden="true"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </span>
                  </button>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-body">
                  <div _ngcontent-ng-c1207450300="" className="ms-3">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div _ngcontent-ng-c1207450300="" className="col-2">
                        Current
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-8">
                        <strong
                          _ngcontent-ng-c1207450300=""
                          className="ng-star-inserted"
                        >
                          {currentClient?.creditReference}
                        </strong>
                        {/**/}
                        {/**/}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="col-md-2 col-form-label"
                      >
                        New
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-md-8">
                        <input
                          _ngcontent-ng-c1207450300=""
                          type="number"
                          min={0}
                          max={999999999999}
                          required=""
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              newLimit: e.target.value,
                              oldLimit: currentClient?.creditReference,
                            });
                          }}
                          value={payload?.newLimit}
                          name="newLimit"
                          className="text-right form-control maxlength12 ng-untouched ng-pristine ng-invalid ng-star-inserted"
                        />
                        {/**/}
                        {/**/}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="col-md-2 col-form-label"
                      >
                        Password
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-md-8">
                        <div
                          _ngcontent-ng-c1207450300=""
                          className="d-flex align-items-center position-relative"
                        >
                          <input
                            _ngcontent-ng-c1207450300=""
                            autoComplete="current-password"
                            id="masterpassword2"
                            name="password"
                            value={payload?.transactionPassword}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                transactionPassword: e.target.value,
                              });
                            }}
                            required=""
                            className="form-control ng-untouched ng-pristine ng-invalid"
                            type="password"
                          />
                          <a _ngcontent-ng-c1207450300="" className="eyebtn">
                            <i
                              _ngcontent-ng-c1207450300=""
                              className="far fa-eye"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-footer">
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleModalSubmit("creditLimit");
                    }}
                  >
                    Submit
                  </button>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-default"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1207450300=""
            id="modalCreditReference"
            tabIndex={-1}
            aria-labelledby="yourModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c1207450300="" className="modal-dialog modal-xl">
              <div _ngcontent-ng-c1207450300="" className="modal-content">
                <div
                  _ngcontent-ng-c1207450300=""
                  className="modal-header bg-primary"
                >
                  <h5 _ngcontent-ng-c1207450300="" className="modal-title">
                    Credit Reference Log -
                  </h5>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="close"
                  >
                    <span
                      _ngcontent-ng-c1207450300=""
                      aria-hidden="true"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </span>
                  </button>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-body"></div>
              </div>
            </div>
          </div>

          <div
            _ngcontent-ng-c1207450300=""
            id="modalExposureDetail"
            tabIndex={-1}
            aria-labelledby="yourModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c1207450300="" className="modal-dialog modal-xl">
              <div _ngcontent-ng-c1207450300="" className="modal-content">
                {marketDetails && (
                  <div
                    _ngcontent-ng-c1207450300=""
                    tabIndex={-1}
                    aria-labelledby="yourModalLabel"
                    aria-hidden="true"
                    style={{
                      display: "flex",
                      zIndex: 99999999,
                      overflowX: "scroll",
                      top: "10%",
                    }}
                    className="modal show fade"
                  >
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="modal-dialog modal-xl"
                    >
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="modal-content"
                      >
                        <div
                          _ngcontent-ng-c1640946631=""
                          className="custom-modal modal-xl"
                        >
                          <div
                            _ngcontent-ng-c1640946631=""
                            className="modal-header bg-primary"
                          >
                            <h4
                              _ngcontent-ng-c1640946631=""
                              id="modal-basic-title"
                              className="modal-title"
                            >
                              {" "}
                              Market Exposure
                            </h4>
                            <button
                              _ngcontent-ng-c1640946631=""
                              type="button"
                              className="close"
                              onClick={() => setMarketDetails(false)}
                            >
                              <span
                                _ngcontent-ng-c1640946631=""
                                aria-hidden="true"
                              >
                                ×
                              </span>
                            </button>
                          </div>

                          <div
                            _ngcontent-ng-c1640946631=""
                            className="custom-modal-body"
                          >
                            <table
                              _ngcontent-ng-c1640946631=""
                              id="example"
                              className="display nowrap table table-responsive table-striped table-bordered"
                            >
                              <thead _ngcontent-ng-c1640946631="">
                                <tr _ngcontent-ng-c1640946631="" role="row">
                                  <th
                                    _ngcontent-ng-c1640946631=""
                                    role="columnheader"
                                    scope="col"
                                    aria-colindex={2}
                                    className="text-center"
                                  >
                                    Event Name{" "}
                                  </th>
                                  <th
                                    _ngcontent-ng-c1640946631=""
                                    role="columnheader"
                                    scope="col"
                                    aria-colindex={3}
                                    className="text-center"
                                  >
                                    Market Name{" "}
                                  </th>
                                  <th
                                    _ngcontent-ng-c1640946631=""
                                    role="columnheader"
                                    scope="col"
                                    aria-colindex={4}
                                    className="text-center"
                                  >
                                    Runner Name{" "}
                                  </th>
                                  <th
                                    _ngcontent-ng-c1640946631=""
                                    role="columnheader"
                                    scope="col"
                                    aria-colindex={5}
                                    className="text-center"
                                  >
                                    {" "}
                                    Bet Type
                                  </th>

                                  <th
                                    _ngcontent-ng-c1640946631=""
                                    role="columnheader"
                                    scope="col"
                                    aria-colindex={7}
                                    className="text-center"
                                  >
                                    Rate{" "}
                                  </th>
                                  <th
                                    _ngcontent-ng-c1640946631=""
                                    role="columnheader"
                                    scope="col"
                                    aria-colindex={8}
                                    className="text-center"
                                  >
                                    Amount{" "}
                                  </th>
                                  <th
                                    _ngcontent-ng-c1640946631=""
                                    role="columnheader"
                                    scope="col"
                                    aria-colindex={9}
                                    className="text-center"
                                  >
                                    Place Date{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody _ngcontent-ng-c1640946631="">
                                {marketDetails &&
                                  marketDetails?.bets?.map((item) => (
                                    <tr
                                      _ngcontent-ng-c1640946631=""
                                      className={`${
                                        item?.back ? "back" : "lay"
                                      } ng-star-inserted`}
                                    >
                                      <td
                                        _ngcontent-ng-c1640946631=""
                                        className="text-center"
                                      >
                                        {marketDetails?.eventType}
                                      </td>
                                      <td
                                        _ngcontent-ng-c1640946631=""
                                        className="text-center"
                                      >
                                        {item?.marketName}
                                      </td>
                                      <td
                                        _ngcontent-ng-c1640946631=""
                                        className="text-center"
                                      >
                                        {item?.runnerName}
                                      </td>
                                      <td
                                        _ngcontent-ng-c1640946631=""
                                        className="text-center"
                                      >
                                        {item?.back ? "BACK" : "LAY"}
                                      </td>
                                      <td
                                        _ngcontent-ng-c1640946631=""
                                        className="text-center"
                                      >
                                        {item?.rate}
                                      </td>

                                      <td
                                        _ngcontent-ng-c1640946631=""
                                        className="text-center"
                                      >
                                        {item?.amount}
                                      </td>

                                      <td
                                        _ngcontent-ng-c1640946631=""
                                        className="text-center"
                                      >
                                        {moment(item?.placeDate + "Z").format(
                                          "DD-MM-YYYY HH:mm:ss"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                {/**/}
                                {/**/}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  _ngcontent-ng-c1207450300=""
                  className="modal-header bg-primary"
                >
                  <h5 _ngcontent-ng-c1207450300="" className="modal-title">
                    Exposure Details-
                  </h5>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="close"
                  >
                    <span
                      _ngcontent-ng-c1207450300=""
                      aria-hidden="true"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </span>
                  </button>
                </div>

                <div
                  style={{
                    overflowX: "scroll",
                  }}
                  _ngcontent-ng-c1207450300=""
                  className="modal-body"
                >
                  <table className="table table-bordered table-hover dataTable no-footer">
                    <thead>
                      <tr>
                        {userBets?.length > 0 &&
                          Object.keys(userBets[0]).map((item) => (
                            <th>{item?.toUpperCase()}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {userBets?.map((item) => (
                        <tr>
                          {Object.keys(item).map((val) => (
                            <td
                              onClick={() => {
                                if (val?.toUpperCase() === "EVENTNAME") {
                                  setMarketDetails(item);
                                  getBetByEventsId(item);
                                }
                              }}
                              style={{
                                textDecoration:
                                  val?.toUpperCase() === "EVENTNAME" &&
                                  "underline",
                                color:
                                  val?.toUpperCase() === "EVENTNAME" && "blue",
                              }}
                            >
                              {item[val]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1207450300=""
            id="depositWithdrawModal"
            tabIndex={-1}
            aria-labelledby="yourModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c1207450300="" className="modal-dialog modal-md">
              <div _ngcontent-ng-c1207450300="" className="modal-content">
                <div
                  _ngcontent-ng-c1207450300=""
                  className="modal-header bg-primary"
                >
                  <h5 _ngcontent-ng-c1207450300="" className="modal-title">
                    {" "}
                    Banking -{" "}
                    <strong _ngcontent-ng-c1207450300="">
                      Master Balance: {currentClient?.balance}{" "}
                    </strong>
                  </h5>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="close"
                  >
                    <span
                      _ngcontent-ng-c1207450300=""
                      aria-hidden="true"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </span>
                  </button>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-body">
                  <div _ngcontent-ng-c1207450300="" className="col-md-12">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div _ngcontent-ng-c1207450300="" className="col-7">
                        <span
                          _ngcontent-ng-c1207450300=""
                          className="badge badge-success me-2"
                        >
                          {currentClient?.userType}
                        </span>
                        <strong _ngcontent-ng-c1207450300="">
                          {currentClient?.username}
                        </strong>
                      </div>
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="col-5 text-end"
                      >
                        <span _ngcontent-ng-c1207450300="">
                          Client Bal :{" "}
                          <b _ngcontent-ng-c1207450300="">
                            {currentClient?.balance}
                          </b>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-ng-c1207450300="" className="col-md-12">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="col-md-2 col-form-label"
                      >
                        Balance
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-md-8">
                        <input
                          _ngcontent-ng-c1207450300=""
                          type="number"
                          min={0}
                          max={9999999999}
                          step={1}
                          onChange={(e) =>
                            setPayload({ ...payload, amount: e.target.value })
                          }
                          value={payload.amount}
                          name="amount"
                          required=""
                          className="text-right form-control maxlength10 ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-ng-c1207450300="" className="col-md-12">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="col-md-2 col-form-label"
                      >
                        Remark
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-md-8">
                        <input
                          _ngcontent-ng-c1207450300=""
                          autoComplete="username"
                          id="balanceRemark"
                          name="balanceRemark"
                          placeholder="Remark.."
                          onChange={(e) =>
                            setPayload({ ...payload, remarks: e.target.value })
                          }
                          type="text"
                          className="form-control ng-untouched ng-pristine ng-valid"
                        />
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-ng-c1207450300="" className="col-md-12">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="form-group row"
                    >
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="col-md-2 col-form-label"
                      >
                        Your Password
                      </div>
                      <div _ngcontent-ng-c1207450300="" className="col-md-8">
                        <div
                          _ngcontent-ng-c1207450300=""
                          className="d-flex align-items-center position-relative"
                        >
                          <input
                            onChange={(e) =>
                              setPayload({
                                ...payload,
                                transactionPassword: e.target.value,
                              })
                            }
                            _ngcontent-ng-c1207450300=""
                            autoComplete="current-password"
                            id="masterpassword1"
                            name="masterpassword1"
                            required=""
                            className="form-control ng-untouched ng-pristine ng-invalid"
                            type="password"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-footer">
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="btn btn-success"
                    disabled=""
                    onClick={() => handleModalSubmit("deposit")}
                  >
                    Deposite
                  </button>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="btn btn-danger"
                    disabled=""
                    onClick={() => handleModalSubmit("withdraw")}
                  >
                    Withdraw
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1207450300=""
            id="changeUserStatusModal"
            tabIndex={-1}
            aria-labelledby="yourModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c1207450300="" className="modal-dialog">
              <div _ngcontent-ng-c1207450300="" className="modal-content">
                <div
                  _ngcontent-ng-c1207450300=""
                  className="modal-header bg-primary"
                >
                  <h5 _ngcontent-ng-c1207450300="" className="modal-title">
                    Change Status
                  </h5>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="close"
                  >
                    <span
                      _ngcontent-ng-c1207450300=""
                      aria-hidden="true"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </span>
                  </button>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-body">
                  <div _ngcontent-ng-c1207450300="">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="d-flex justify-content-between"
                    >
                      <p _ngcontent-ng-c1207450300="">
                        <span
                          _ngcontent-ng-c1207450300=""
                          className="badge badge-success"
                        >
                          {currentClient?.userType}
                        </span>{" "}
                        {currentClient?.username}
                      </p>
                      <p _ngcontent-ng-c1207450300="">
                        <span
                          _ngcontent-ng-c1207450300=""
                          className={`badge badge-${
                            currentClient?.isActive ? "success" : "secondary"
                          } ${payload?.isActive && "active"}`}
                        >
                          {currentClient?.isActive ? "active" : "locked"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div _ngcontent-ng-c1207450300="" className="divider">
                    {currentClient?.isActive}
                  </div>
                  <div
                    _ngcontent-ng-c1207450300=""
                    className="row align-items-center stat-btn-list"
                  >
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"
                    >
                      <button
                        onClick={() => {
                          setPayload({
                            ...payload,
                            status: "active",
                          });
                        }}
                        _ngcontent-ng-c1207450300=""
                        type="button"
                        className={`${
                          payload?.status == "active" && "active"
                        } btn btn-block btn-outline-success w-100`}
                      >
                        <div _ngcontent-ng-c1207450300="" className="stat-icon">
                          <i
                            _ngcontent-ng-c1207450300=""
                            className="far fa-check-circle fa-2x"
                          />
                        </div>
                        <div _ngcontent-ng-c1207450300="" className="stat-text">
                          Active
                        </div>
                      </button>
                    </div>
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"
                    >
                      <button
                        onClick={() => {
                          setPayload({
                            ...payload,
                            status: "suspended",
                          });
                        }}
                        _ngcontent-ng-c1207450300=""
                        type="button"
                        className={`${
                          payload?.status == "suspended" && "active"
                        } btn btn-block btn-outline-danger w-100`}
                      >
                        <div _ngcontent-ng-c1207450300="" className="stat-icon">
                          <i
                            _ngcontent-ng-c1207450300=""
                            className="fa fa-ban fa-2x"
                          />
                        </div>
                        <div _ngcontent-ng-c1207450300="" className="stat-text">
                          Suspend
                        </div>
                      </button>
                    </div>
                    {/*  <div
                      _ngcontent-ng-c1207450300=""
                      className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"
                    >
                      <button
                        onClick={() => {
                          setPayload({
                            ...payload,
                            status: "locked",
                          });
                        }}
                        _ngcontent-ng-c1207450300=""
                        type="button"
                        className="btn btn-block btn-outline-secondary w-100"
                      >
                        <div _ngcontent-ng-c1207450300="" className="stat-icon">
                          <i
                            _ngcontent-ng-c1207450300=""
                            className="fa fa-lock fa-2x"
                          />
                        </div>
                        <div _ngcontent-ng-c1207450300="" className="stat-text">
                          Locked
                        </div>
                      </button>
                    </div> */}
                  </div>
                  <div _ngcontent-ng-c1207450300="" className="divider" />
                  <div _ngcontent-ng-c1207450300="" className="row">
                    <div _ngcontent-ng-c1207450300="" className="col-md-6">
                      <div
                        _ngcontent-ng-c1207450300=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c1207450300=""
                          autoComplete="new-password"
                          name="rate"
                          placeholder="Password.."
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              transactionPassword: e.target.value,
                            })
                          }
                          value={payload?.transactionPassword}
                          required=""
                          className="form-control ng-untouched ng-pristine ng-invalid"
                          type={
                            payload?.showMasterPassword ? "text" : "password"
                          }
                        />
                        <a _ngcontent-ng-c1207450300="" className="eyebtn">
                          <i
                            _ngcontent-ng-c1207450300=""
                            className={`far fa-${
                              !payload?.showMasterPassword ? "eye-slash" : "eye"
                            }`}
                            onClick={() =>
                              setPayload({
                                ...payload,
                                showMasterPassword:
                                  !payload?.showMasterPassword,
                              })
                            }
                          />
                        </a>
                      </div>
                    </div>
                    <div _ngcontent-ng-c1207450300="" className="col-md-6">
                      <button
                        _ngcontent-ng-c1207450300=""
                        type="button"
                        onClick={() => handleModalSubmit("status")}
                        className="btn btn-primary change-status"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1207450300=""
            id="sportSettingModal"
            tabIndex={-1}
            aria-labelledby="yourModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c1207450300="" className="modal-dialog">
              <div _ngcontent-ng-c1207450300="" className="modal-content">
                <div
                  _ngcontent-ng-c1207450300=""
                  className="modal-header bg-primary"
                >
                  <h5 _ngcontent-ng-c1207450300="" className="modal-title">
                    Sports Settings
                  </h5>
                  <button
                    _ngcontent-ng-c1207450300=""
                    type="button"
                    className="close"
                  >
                    <span
                      _ngcontent-ng-c1207450300=""
                      aria-hidden="true"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </span>
                  </button>
                </div>
                <div _ngcontent-ng-c1207450300="" className="modal-body">
                  <table
                    _ngcontent-ng-c1207450300=""
                    className="table table-bordered mb-0"
                  >
                    <thead _ngcontent-ng-c1207450300="" className="bg-default">
                      <tr _ngcontent-ng-c1207450300="">
                        <th _ngcontent-ng-c1207450300="">SrNo.</th>
                        <th _ngcontent-ng-c1207450300="">Sport Name</th>
                        <th _ngcontent-ng-c1207450300="">Action</th>
                      </tr>
                    </thead>
                    <tbody _ngcontent-ng-c1207450300="">
                      {["Cricket", "Tennis", "Casino", "Soccer"].map(
                        (sport, index) => (
                          <tr
                            _ngcontent-ng-c1207450300=""
                            className="ng-star-inserted"
                          >
                            <td _ngcontent-ng-c1207450300="">{index + 1}</td>
                            <td _ngcontent-ng-c1207450300="">{sport}</td>
                            <td _ngcontent-ng-c1207450300="">
                              <div
                                _ngcontent-ng-c1207450300=""
                                className="nav-item d-inline-block"
                              >
                                <div
                                  _ngcontent-ng-c1207450300=""
                                  className="form-check form-check-inline ml-2"
                                  style={{ float: "left" }}
                                >
                                  <label
                                    _ngcontent-ng-c1207450300=""
                                    className="switch switch-label switch-primary switch-sm mr-2"
                                    htmlFor="switch2Cricket"
                                    onClick={() =>
                                      setPayload({
                                        ...payload,
                                        ["is" + sport + "Locked"]:
                                          !payload["is" + sport + "Locked"],
                                      })
                                    }
                                  >
                                    <input
                                      _ngcontent-ng-c1207450300=""
                                      name="checkboxtoogle"
                                      type="checkbox"
                                      style={{
                                        display: "block",
                                        position: "absolute",
                                        zIndex: 10000,
                                        opacity: 0.1,
                                      }}
                                      defaultChecked={
                                        !payload["is" + sport + "Locked"]
                                      }
                                      checked={
                                        !payload["is" + sport + "Locked"]
                                      }
                                      className="switch-input "
                                      value={!payload["is" + sport + "Locked"]}
                                    />
                                    <span
                                      _ngcontent-ng-c1207450300=""
                                      data-checked="✓"
                                      data-unchecked="✕"
                                      className="switch-slider "
                                    />
                                  </label>
                                </div>
                              </div>

                              <button
                                _ngcontent-ng-c1207450300=""
                                className="d-none"
                                id="switch2Cricket"
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <div _ngcontent-ng-c1207450300="" className="col-md-12 my-1">
                    <div
                      _ngcontent-ng-c1207450300=""
                      className="d-flex align-items-center position-relative"
                    >
                      <input
                        _ngcontent-ng-c1207450300=""
                        autoComplete="new-password"
                        name="rate"
                        placeholder="Password.."
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            transactionPassword: e.target.value,
                          })
                        }
                        value={payload?.transactionPassword}
                        required=""
                        className="form-control ng-untouched ng-pristine ng-invalid"
                        type="password"
                      />
                      <a _ngcontent-ng-c1207450300="" className="eyebtn">
                        <i
                          _ngcontent-ng-c1207450300=""
                          className="far fa-eye"
                        />
                      </a>
                    </div>
                  </div>
                  <div _ngcontent-ng-c1207450300="" className="col-md-12">
                    <button
                      _ngcontent-ng-c1207450300=""
                      type="button"
                      onClick={() => handleModalSubmit("status", "sport")}
                      className="btn btn-primary change-status"
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-user-list>
        {/**/}
      </div>
    </div>
  );
}
