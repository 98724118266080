import { Box, Button, Grid, GridItem, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import "./game.css";
import "./all.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  betAndFancyLocksCricketAPI,
  getAdminBookMakerCricketAPI,
  getAdminPlExposureCricketAPI,
  getAdminSkyBookMakerCricketAPI,
  getAdminUserBookCricketAPI,
  getBetActiveDeactiveAPI,
  getBetActiveDeactiveCricketAPI,
  getClientBetsCricketAPI,
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
} from "../../service/cricket";
import BetsLock from "./components/BetsLock";
import ViewMoreBet from "./components/ViewMoreBet";
import moment from "moment/moment";
import BookMakersBook from "./components/BookMakers";
import {
  betAndFancyLocksSoccerAPI,
  betAndFancyLocksTennisAPI,
  getAdminBookMakerSoccerAPI,
  getAdminBookMakerTennisAPI,
  getAdminPlExposureSoccerAPI,
  getAdminPlExposureTennisAPI,
  getAdminSkyBookMakerSoccerAPI,
  getAdminSkyBookMakerTennisAPI,
  getAdminUserBookSoccerAPI,
  getAdminUserBookTennisAPI,
  getBetActiveDeactiveSoccerAPI,
  getBetActiveDeactiveTennisAPI,
  getClientBetsSoccerAPI,
  getClientBetsTennisAPI,
  getSoccerEventDetailsAPI,
  getSoccerOddsByMatchIdAPI,
  getTennisEventDetailsAPI,
  getTennisOddsByMatchIdAPI,
} from "../../service/soccer&tennis";
import { getLiveStreamingUrlAPI } from "../../service/AuthService";
import Ladder from "./components/Ladder";
import { set } from "lodash";

export default function Soccer() {
  const [gameData, setGameData] = useState({
    gameName: "Cricket",
    gameType: "Match",
    time: "Jan 02,2023 10:00 AM",
  });
  const toast = useToast();
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const location = useLocation();
  const [showMarket, setShowMarket] = useState();
  const gameOdds = useSelector((state) => state.gameData);
  const gameDetails = useSelector((state) => state.gameData);
  const [selectedMarket, setSelectedMarket] = useState("all");
  const [bookOpen, setBookOpen] = useState(false);
  const [userBetStatus, setUserBetStatus] = useState(false);
  const [fancyBetStatus, setFancyBetStatus] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedFancy, setSelectedFancy] = useState("NORMAL");
  const [matchData, setMatchData] = useState([]);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [options, setOptions] = useState({
    liveBet: false,
    partnershipBook: false,
  });
  const [isLiveTvOpen, setIsLiveTvOpen] = useState(false);
  const [gameSettings_, setGameSettings_] = useState({});
  const [everyRunnerExpousure, setEveryRunnerExpousure] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("book");
  const [userBook, setUserBook] = React.useState({});
  const [skyBook, setSkyBook] = React.useState([]);
  let items = location.pathname.split("/");
  var gameType = items[items.length - 2];
  var eventId = items[items.length - 1];
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const getGameEventDetails = useCallback(async () => {
    const { response } = await getSoccerEventDetailsAPI(eventId);
    setMatchDetails(response);
    return response;
  }, [eventId, gameType]);
  useEffect(() => {
    getGameEventDetails();

    //setGameSettings_(gameSettings[eventId]);
  }, [location.pathname]);
  const getCricketPlExposure = async () => {
    const { response } = await getAdminPlExposureSoccerAPI(eventId);
    if (response) {
      setMatchData(response);
    }
  };
  const getClientBets = async (betStatus_) => {
    const { response } = await getClientBetsSoccerAPI(
      eventId,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setClientBets(response);
    }
  };
  const applyBlinkEffect = (element) => {
    element?.classList?.add("blink");

    setTimeout(() => {
      element?.classList?.remove("blink");
    }, 1000);
  };

  useEffect(() => {
    const layButtons = document.querySelectorAll(".box-w1");
    const observers = [];
    layButtons.forEach((layButton) => {
      const observer = new MutationObserver(() => {
        applyBlinkEffect(layButton);
      });
      observer.observe(layButton, {
        childList: true,
        subtree: true,
        characterData: true,
      });
      observers.push(observer);
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, [gameOdds]);
  const getBetActiveDeactive = async () => {
    const { response } = await getBetActiveDeactiveSoccerAPI(eventId);
    if (response) {
      setUserBetStatus(response?.betLock);
      setFancyBetStatus(response?.fancyLock);
    }
  };
  const checkIfFloat = (value) => {
    return value % 1 != 0 ? parseFloat(value).toFixed(2) : parseInt(value);
  };
  const getBookMakerBook = async (type = "book") => {
    let data;
    gameType = "cricket";
    if (type == "user") {
      if (gameType === "cricket") {
        const { response, code } = await getAdminUserBookTennisAPI(eventId);
        if (response && code == 200) {
          data = response;
        }
      } else if (gameType === "soccer") {
        const { response, code } = await getAdminUserBookSoccerAPI(eventId);
        if (response && code == 200) {
          data = response;
        }
      } else if (gameType === "tennis") {
        const { response, code } = await getAdminUserBookTennisAPI(eventId);
        if (response && code == 200) {
          data = response;
        }
      }
    } else if (type == "book") {
      if (gameType === "cricket") {
        const { response, code } = await getAdminBookMakerCricketAPI(eventId);
        if (response && code == 200) {
          data = response;
        }
      } else if (gameType === "soccer") {
        const { response, code } = await getAdminBookMakerSoccerAPI(eventId);
        if (response && code == 200) {
          data = response;
        }
      } else if (gameType === "tennis") {
        const { response, code } = await getAdminBookMakerTennisAPI(eventId);
        if (response && code == 200) {
          data = response;
        }
      }
    }
    if (data && data.length > 0) {
      let rows_ = [];
      let columns_ = [];
      data?.map((item) => {
        let row = {};
        row.userName = item?.userName;
        item?.runnerBookResponseDTOS?.map((match) => {
          row[match?.runnerName] = match?.pandL;
        });
        rows_.push(row);
      });
      if (rows_.length > 0) {
        columns_.push({ name: "User Name", id: "userName" });
        Object.keys(rows_[0]).map((key) => {
          if (key !== "userName") {
            columns_.push({ name: key, id: key });
          }
        });

        setUserBook({
          columns: columns_,
          rows: rows_,
        });
      }
    }
  };
  useEffect(() => {
    const observers = [];

    const intervalId = setInterval(() => {
      const layButtons = document.querySelectorAll(".match-inn-txt-top");
      if (layButtons.length > 0) {
        clearInterval(intervalId);
        layButtons.forEach((layButton) => {
          const observer = new MutationObserver(() => {
            applyBlinkEffect(layButton.parentElement, "Back");
          });
          observer.observe(layButton, {
            childList: true,
            subtree: true,
            characterData: true,
          });

          observers.push(observer);
        });
      }
      const buttons = document.querySelectorAll(".match-inn-txt-btm");
      if (buttons.length > 0) {
        clearInterval(intervalId);
        buttons.forEach((layButton) => {
          const observer = new MutationObserver(() => {
            applyBlinkEffect(buttons.parentElement, "Back");
          });
          observer.observe(layButton, {
            childList: true,
            subtree: true,
            characterData: true,
          });

          observers.push(observer);
        });
      }
      //match-inn-txt-btm
    }, 1000); // Check every 1 second

    return () => {
      // Cleanup: clear interval and disconnect observers
      clearInterval(intervalId);
      observers.forEach((observer) => observer.disconnect());
    };
  }, []);
  const getCricketOddsByMatchId = useCallback(async () => {
    const { response, code } = await getSoccerOddsByMatchIdAPI(eventId);
    if (code != 200) {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      window.location.href = "/";
      return;
    }
    let response_ = {};
    response_[eventId] = response;
    setMatchOdds(response);
    dispatch({
      type: "gameData/setTennisOdds",
      payload: response_,
    });
  }, [eventId]);
  const getLadderForMarket = (marketId) => {
    return matchData?.find((i) => i.marketIdExternal === marketId)
      ?.runnerPlExposureDTOS;
  };

  useEffect(() => {
    if (eventId) {
      getCricketPlExposure();
      getClientBets();
      let interval = setInterval(() => {
        getCricketPlExposure();
        getClientBets();
        getBetActiveDeactive();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [eventId]);
  useEffect(() => {
    let interval = setInterval(() => {
      getCricketOddsByMatchId();
    }, 3000);
    return () => clearInterval(interval);
  }, [eventId]);
  const betAndFancyLocks = async (name, lock) => {
    let payload = {
      tennisId: eventId,
    };
    if (name == "betlock") {
      payload.isBetLockedAll = lock;
    } else {
      payload.isFancyLockedALl = lock;
    }
    var response_;
    switch (gameType) {
      case "cricket":
        var { response } = await betAndFancyLocksCricketAPI(payload);
        response_ = response;
        break;
      case "soccer":
        var { response } = await betAndFancyLocksSoccerAPI(payload);
        response_ = response;
        break;
      case "tennis":
        var { response } = await betAndFancyLocksTennisAPI(payload);
        response_ = response;
        break;
    }

    if (response_) {
      toast({
        title: "Success",
        description: response_?.message,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      setMatchData(response_);
      getBetActiveDeactive();
    }
  };
  const getExposure = (match, marketId, marketName = "FANCY") => {
    let exposure_;
    if (marketName == "FANCY") {
      exposure_ = matchData?.filter(
        (item) => item.marketIdExternal == match.marketId
      );

      if (exposure_ && exposure_.length > 0) return exposure_[0];
      else return null;
    } else {
      for (let i = 0; i < matchData?.length; i++) {
        let item = matchData[i];
        if (item.marketIdExternal == marketId?.marketId) {
          for (let j = 0; j < item?.runnerPlExposureDTOS?.length; j++) {
            let event = item?.runnerPlExposureDTOS[j];
            if (event?.selectionId == match?.selectionId) {
              exposure_ = event;
            }
          }
        }
      }
    }

    return exposure_;
  };
  const getSkyBook = async () => {
    const { response } = await getAdminSkyBookMakerSoccerAPI(
      bookOpen,
      eventId,
      selectedBook
    );
    if (response) {
      setSkyBook(response);
    }
  };
  useEffect(() => {
    if (bookOpen && eventId && selectedBook) getSkyBook();
  }, [selectedBook]);
  const getCombinedExposureFromAll = (odds) => {
    let uniqueRunnerName = [];
    matchOdds?.matchOddsResponseDTO[0]?.oddDetailsDTOS?.map((item) => {
      if (!uniqueRunnerName.includes(item.runnerName)) {
        uniqueRunnerName.push(item.runnerName);
      }
    });

    matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS?.map((item) => {
      if (!uniqueRunnerName.includes(item.runnerName)) {
        uniqueRunnerName.push(item.runnerName);
      }
    });
    uniqueRunnerName = uniqueRunnerName.filter((item) => item != null);

    let exposure = 0;
    let combinedRunnerObject = {};
    uniqueRunnerName.map((item) => {
      let exposure_ = 0;
      let matchOddsExpousure = getExposure(
        matchOdds?.matchOddsResponseDTO[0]?.oddDetailsDTOS?.filter(
          (item_) => item_.runnerName == item
        )[0],
        matchOdds?.matchOddsResponseDTO[0],
        "MATCH_ODDS"
      );
      if (matchOddsExpousure) exposure_ += matchOddsExpousure?.exposure;
      let bookMakerOddsExpousure = getExposure(
        matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS?.filter(
          (item_) => item_.runnerName == item
        )[0],
        matchOdds?.bookMakerOdds[0]?.bm1,
        "MATCH_ODDS"
      );
      if (bookMakerOddsExpousure) exposure_ += bookMakerOddsExpousure?.exposure;
      combinedRunnerObject[item] = parseFloat(exposure_).toFixed(2);
      exposure += exposure_;
    });

    return combinedRunnerObject;
  };
  const getLiveStreamingUrl = async () => {
    let gameId = await getGameEventDetails();
    const { response } = await getLiveStreamingUrlAPI(gameId?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [eventId]);
  useEffect(() => {
    getBookMakerBook(selectedTab);
  }, [selectedTab]);
  return (
    <div _ngcontent-ng-c3796931036="" className="main">
      <div _ngcontent-ng-c3796931036="" className="container-fluid">
        <router-outlet _ngcontent-ng-c3796931036="" />
        
        <app-gamedetails _nghost-ng-c3599551908="" className="ng-star-inserted">
          <div _ngcontent-ng-c3599551908="" className="animated fadeIn">
            <div _ngcontent-ng-c3599551908="" className="row">
              {/**/}
              <div
                _ngcontent-ng-c3599551908=""
                className="col-lg-7 col-sm-12 col-12 analysis-sec mb-1 p-0 ng-star-inserted"
              >
                {matchDetails && (
                  <iframe
                    style={{ width: "100%", height: "200px" }}
            src={`https://animscore.proexch.in/#/score1/${matchDetails?.eventId}`}
          />
        )}
                <div
                  _ngcontent-ng-c3599551908=""
                  className="card matchodd matchodd-cont ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c3599551908=""
                    className="card-matchodds outer-divs"
                  >
                    <strong
                      _ngcontent-ng-c3599551908=""
                      className="match-odds outer-div1"
                    >
                      Match Odds{" "}
                      <span
                        _ngcontent-ng-c3599551908=""
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                        className="marketinfo ml-2"
                      >
                        <svg
                          _ngcontent-ng-c3599551908=""
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          viewBox="0 0 15 15"
                        >
                          <path
                            _ngcontent-ng-c3599551908=""
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M6.76 5.246V3.732h1.48v1.514H6.76zm.74 8.276a5.86 5.86 0 0 0 3.029-.83 5.839 5.839 0 0 0 2.163-2.163 5.86 5.86 0 0 0 .83-3.029 5.86 5.86 0 0 0-.83-3.029 5.839 5.839 0 0 0-2.163-2.163 5.86 5.86 0 0 0-3.029-.83 5.86 5.86 0 0 0-3.029.83A5.839 5.839 0 0 0 2.308 4.47a5.86 5.86 0 0 0-.83 3.029 5.86 5.86 0 0 0 .83 3.029 5.839 5.839 0 0 0 2.163 2.163 5.86 5.86 0 0 0 3.029.83zM7.5 0c1.37 0 2.638.343 3.804 1.028a7.108 7.108 0 0 1 2.668 2.668A7.376 7.376 0 0 1 15 7.5c0 1.37-.343 2.638-1.028 3.804a7.108 7.108 0 0 1-2.668 2.668A7.376 7.376 0 0 1 7.5 15a7.376 7.376 0 0 1-3.804-1.028 7.243 7.243 0 0 1-2.668-2.686A7.343 7.343 0 0 1 0 7.5c0-1.358.343-2.62 1.028-3.786a7.381 7.381 0 0 1 2.686-2.686A7.343 7.343 0 0 1 7.5 0zm-.74 11.268V6.761h1.48v4.507H6.76z"
                          />
                        </svg>
                      </span>
                    </strong>
                    <span
                      _ngcontent-ng-c3599551908=""
                      className="matched-count pull-right outer-div4 ng-star-inserted"
                    >
                      Matched{" "}
                      <strong _ngcontent-ng-c3599551908="">€ 10.20M</strong>
                    </span>
                    {/**/}
                    {/**/}
                  </div>
                  <div _ngcontent-ng-c3599551908="" className="card-body p-0">
                    <div
                      _ngcontent-ng-c3599551908=""
                      className="table-responsive position-relative"
                    >
                      {/**/}
                      <table
                        _ngcontent-ng-c3599551908=""
                        className="table table-hover tbl-bets betfair-tbl p-rltv"
                      >
                        <thead _ngcontent-ng-c3599551908="">
                          <tr>
                            <th
                              _ngcontent-ng-c3599551908=""
                              className="showMinMaxMobile show-xs min-max-mobile"
                            >
                              <dl
                                _ngcontent-ng-c3599551908=""
                                id="minMaxBox"
                                className="fancy-info matchoddsminmax"
                              >
                                <dt _ngcontent-ng-c3599551908="">Min/Max</dt>
                                <dd
                                  _ngcontent-ng-c3599551908=""
                                  id="minMaxInfo"
                                >
                                  100-10000
                                </dd>
                              </dl>
                            </th>
                            <th
                              _ngcontent-ng-c3599551908=""
                              colSpan={3}
                              className="hidden-xs boxHide"
                            />
                            <th
                              _ngcontent-ng-c3599551908=""
                              className="back-1 mobileTableHead"
                            >
                              Back
                            </th>
                            <th
                              _ngcontent-ng-c3599551908=""
                              className="lay-1 mobileTableHead"
                            >
                              Lay
                            </th>
                            <th
                              _ngcontent-ng-c3599551908=""
                              colSpan={2}
                              className="min-max-bet boxHide"
                            >
                              <dl
                                _ngcontent-ng-c3599551908=""
                                id="minMaxBox"
                                className="fancy-info matchoddsminmax"
                              >
                                <dt _ngcontent-ng-c3599551908="">Min/Max</dt>
                                <dd
                                  _ngcontent-ng-c3599551908=""
                                  id="minMaxInfo"
                                >
                                  100-10000
                                </dd>
                              </dl>
                            </th>
                          </tr>
                        </thead>

                        {matchOdds?.matchOddsResponseDTO[0]?.oddDetailsDTOS?.map(
                          (game) => (
                            <tbody
                              _ngcontent-ng-c3599551908=""
                              className="position-relative ng-star-inserted"
                            >
                              {game?.status?.toLowerCase() != "active" && (
                                <div
                                  _ngcontent-ng-c3599551908=""
                                  bookmarked_table=""
                                  className="suspend-bookmaker-external unitycss0-7-22-23 ng-star-inserted"
                                >
                                  <span
                                    _ngcontent-ng-c3599551908=""
                                    bookmarked_table=""
                                    className="stats-text unitycss0-8-23-24"
                                  >
                                    {" "}
                                    Suspended{" "}
                                  </span>
                                </div>
                              )}
                              {/**/}
                              {/**/}
                              <tr
                                _ngcontent-ng-c3599551908=""
                                className="odd-row"
                                style={{ position: "relative" }}
                              >
                                <td
                                  _ngcontent-ng-c3599551908=""
                                  className="team-name"
                                >
                                  <span
                                    _ngcontent-ng-c3599551908=""
                                    className="title matchodds_runner"
                                  >
                                    {" "}
                                    {game?.runnerName}{" "}
                                  </span>
                                  <span
                                    _ngcontent-ng-c3599551908=""
                                    className="d-flex"
                                  >
                                    {/**/}
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="ng-star-inserted"
                                    >
                                      <span
                                        _ngcontent-ng-c3599551908=""
                                        className={`float-left profit ${
                                          getExposure(
                                            game,
                                            matchOdds?.matchOddsResponseDTO[0],
                                            "MATCH_ODDS"
                                          )?.exposure > 0
                                            ? "plus"
                                            : "minus"
                                        }-book ng-star-inserted`}
                                      >
                                        {getExposure(
                                          game,
                                          matchOdds?.matchOddsResponseDTO[0],
                                          "MATCH_ODDS"
                                        )?.exposure && (
                                          <i
                                            _ngcontent-ng-c3599551908=""
                                            className="fa fa-arrow-right"
                                          />
                                        )}{" "}
                                        {
                                          getExposure(
                                            game,
                                            matchOdds?.matchOddsResponseDTO[0],
                                            "MATCH_ODDS"
                                          )?.exposure
                                        }{" "}
                                      </span>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                    </span>
                                    {/**/}
                                  </span>
                                </td>
                                <td
                                  _ngcontent-ng-c3599551908=""
                                  className="back-3 count boxHide position-relative"
                                >
                                  <a
                                    _ngcontent-ng-c3599551908=""
                                    appratehighlight=""
                                    className="back"
                                  >
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-book-inner-data match-inn-txt-top"
                                      style={{ fontWeight: 700 }}
                                    >
                                      {game?.back3}
                                    </span>
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-inn-txt-btm"
                                    >
                                      {game?.backSize3}
                                    </span>
                                  </a>
                                </td>
                                <td
                                  _ngcontent-ng-c3599551908=""
                                  className="back-2 count boxHide position-relative"
                                >
                                  <a
                                    _ngcontent-ng-c3599551908=""
                                    appratehighlight=""
                                    className="back"
                                  >
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-book-inner-data match-inn-txt-top"
                                      style={{ fontWeight: 700 }}
                                    >
                                      {game?.back2}
                                    </span>
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-inn-txt-btm"
                                    >
                                      {game?.backSize2}
                                    </span>
                                  </a>
                                </td>
                                <td
                                  _ngcontent-ng-c3599551908=""
                                  className="back-1 count position-relative"
                                >
                                  <a
                                    _ngcontent-ng-c3599551908=""
                                    appratehighlight=""
                                    className="back"
                                  >
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-book-inner-data match-inn-txt-top"
                                      style={{ fontWeight: 700 }}
                                    >
                                      {game?.back1}
                                    </span>
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-inn-txt-btm"
                                    >
                                      {game?.backSize1}
                                    </span>
                                  </a>
                                </td>
                                <td
                                  _ngcontent-ng-c3599551908=""
                                  className="lay-1 count"
                                >
                                  <a
                                    _ngcontent-ng-c3599551908=""
                                    appratehighlight=""
                                    className="lay"
                                  >
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-book-inner-data match-inn-txt-top"
                                      style={{ fontWeight: 700 }}
                                    >
                                      {game?.lay1}
                                    </span>
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-inn-txt-btm"
                                    >
                                      {game?.laySize1}
                                    </span>
                                  </a>
                                </td>
                                <td
                                  _ngcontent-ng-c3599551908=""
                                  className="lay-2 count boxHide"
                                >
                                  <a
                                    _ngcontent-ng-c3599551908=""
                                    appratehighlight=""
                                    className="lay"
                                  >
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-inn-txt-top"
                                      style={{ fontWeight: 700 }}
                                    >
                                      {game?.lay2}
                                    </span>
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-inn-txt-btm"
                                    >
                                      {game?.laySize2}
                                    </span>
                                  </a>
                                </td>
                                <td
                                  _ngcontent-ng-c3599551908=""
                                  className="lay-3 count boxHide"
                                >
                                  <a
                                    _ngcontent-ng-c3599551908=""
                                    appratehighlight=""
                                    className="lay SparkLay"
                                  >
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-inn-txt-top"
                                      style={{ fontWeight: 700 }}
                                    >
                                      {game?.lay3}
                                    </span>
                                    <span
                                      _ngcontent-ng-c3599551908=""
                                      className="position-relative match-inn-txt-btm"
                                    >
                                      {game?.laySize3}
                                    </span>
                                  </a>
                                </td>
                              </tr>
                              {/**/}
                            </tbody>
                          )
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}

                {(selectedMarket?.toLowerCase() == "popular" ||
                  selectedMarket?.toLowerCase() == "all") &&
                  matchOdds &&
                  matchOdds?.fancyOdds &&
                  matchOdds?.fancyOdds?.length > 0 && (
                    <>
                      <div
                        _ngcontent-ng-c3599551908=""
                        className="card"
                        style={{
                          border: "none",
                          marginTop: 38,
                          padding: 0,
                          background: "none",
                          height: 5,
                        }}
                      >
                        <div _ngcontent-ng-c3599551908="" className="p-rltv">
                          <div
                            _ngcontent-ng-c3599551908=""
                            id="fancyBetHead"
                            className="fancy-head"
                          >
                            <h4
                              _ngcontent-ng-c3599551908=""
                              className="in-play"
                            >
                              <span
                                _ngcontent-ng-c3599551908=""
                                id="headerName"
                              >
                                Fancy Bet
                              </span>
                              <a
                                _ngcontent-ng-c3599551908=""
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                                className="btn-fancybet_rules"
                              >
                                <svg
                                  _ngcontent-ng-c3599551908=""
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={15}
                                  height={15}
                                  viewBox="0 0 15 15"
                                >
                                  <path
                                    _ngcontent-ng-c3599551908=""
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    d="M6.76 5.246V3.732h1.48v1.514H6.76zm.74 8.276a5.86 5.86 0 0 0 3.029-.83 5.839 5.839 0 0 0 2.163-2.163 5.86 5.86 0 0 0 .83-3.029 5.86 5.86 0 0 0-.83-3.029 5.839 5.839 0 0 0-2.163-2.163 5.86 5.86 0 0 0-3.029-.83 5.86 5.86 0 0 0-3.029.83A5.839 5.839 0 0 0 2.308 4.47a5.86 5.86 0 0 0-.83 3.029 5.86 5.86 0 0 0 .83 3.029 5.839 5.839 0 0 0 2.163 2.163 5.86 5.86 0 0 0 3.029.83zM7.5 0c1.37 0 2.638.343 3.804 1.028a7.108 7.108 0 0 1 2.668 2.668A7.376 7.376 0 0 1 15 7.5c0 1.37-.343 2.638-1.028 3.804a7.108 7.108 0 0 1-2.668 2.668A7.376 7.376 0 0 1 7.5 15a7.376 7.376 0 0 1-3.804-1.028 7.243 7.243 0 0 1-2.668-2.686A7.343 7.343 0 0 1 0 7.5c0-1.358.343-2.62 1.028-3.786a7.381 7.381 0 0 1 2.686-2.686A7.343 7.343 0 0 1 7.5 0zm-.74 11.268V6.761h1.48v4.507H6.76z"
                                  />
                                </svg>
                              </a>
                            </h4>
                            <h4
                              _ngcontent-ng-c3599551908=""
                              className="in-play-sportbook"
                            >
                              <span
                                _ngcontent-ng-c3599551908=""
                                id="headerName"
                              >
                                Sportbook
                              </span>
                              <a
                                _ngcontent-ng-c3599551908=""
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                                className="btn-sportsbook_rules"
                              >
                                <svg
                                  _ngcontent-ng-c3599551908=""
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={15}
                                  height={15}
                                  viewBox="0 0 15 15"
                                >
                                  <path
                                    _ngcontent-ng-c3599551908=""
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    d="M6.76 5.246V3.732h1.48v1.514H6.76zm.74 8.276a5.86 5.86 0 0 0 3.029-.83 5.839 5.839 0 0 0 2.163-2.163 5.86 5.86 0 0 0 .83-3.029 5.86 5.86 0 0 0-.83-3.029 5.839 5.839 0 0 0-2.163-2.163 5.86 5.86 0 0 0-3.029-.83 5.86 5.86 0 0 0-3.029.83A5.839 5.839 0 0 0 2.308 4.47a5.86 5.86 0 0 0-.83 3.029 5.86 5.86 0 0 0 .83 3.029 5.839 5.839 0 0 0 2.163 2.163 5.86 5.86 0 0 0 3.029.83zM7.5 0c1.37 0 2.638.343 3.804 1.028a7.108 7.108 0 0 1 2.668 2.668A7.376 7.376 0 0 1 15 7.5c0 1.37-.343 2.638-1.028 3.804a7.108 7.108 0 0 1-2.668 2.668A7.376 7.376 0 0 1 7.5 15a7.376 7.376 0 0 1-3.804-1.028 7.243 7.243 0 0 1-2.668-2.686A7.343 7.343 0 0 1 0 7.5c0-1.358.343-2.62 1.028-3.786a7.381 7.381 0 0 1 2.686-2.686A7.343 7.343 0 0 1 7.5 0zm-.74 11.268V6.761h1.48v4.507H6.76z"
                                  />
                                </svg>
                              </a>
                            </h4>
                          </div>
                          <div
                            _ngcontent-ng-c3599551908=""
                            id="fancyBetTabWrap"
                            className="fancy_bet_tab-wrap fancy_bet_back"
                          >
                            <ul
                              _ngcontent-ng-c3599551908=""
                              className="special_bets-tab"
                            >
                              {matchOdds?.fancyOdds?.map((item) => (
                                <li
                                  onClick={() =>
                                    setSelectedFancy(
                                      item?.gameType?.toUpperCase()
                                    )
                                  }
                                  _ngcontent-ng-c3599551908=""
                                  id="popular"
                                  className={
                                    selectedFancy ==
                                      item?.gameType?.toUpperCase() && "select"
                                  }
                                >
                                  <a _ngcontent-ng-c3599551908="" id="popular">
                                    {item?.gameType?.toUpperCase()}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3599551908=""
                        className="card mb-1 fancy-card ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c3599551908=""
                          className="card-body p-0"
                        >
                          <div
                            _ngcontent-ng-c3599551908=""
                            className="table-responsive fency-fency-datatable"
                          >
                            <table
                              _ngcontent-ng-c3599551908=""
                              className="table table-hover tbl-bets fancy-bet p-rltv mb-0"
                            >
                              <thead _ngcontent-ng-c3599551908="">
                                <tr>
                                  <th
                                    _ngcontent-ng-c3599551908=""
                                    colSpan={2}
                                    className="minwidth"
                                  />
                                  <th
                                    _ngcontent-ng-c3599551908=""
                                    className="lay-1"
                                  >
                                    No
                                  </th>
                                  <th
                                    _ngcontent-ng-c3599551908=""
                                    className="back-1"
                                  >
                                    Yes
                                  </th>
                                  <th
                                    _ngcontent-ng-c3599551908=""
                                    className="hidden-xs fancyminmaxText"
                                  >
                                    Min/Max
                                  </th>
                                </tr>
                              </thead>
                              {matchOdds?.fancyOdds
                                ?.find(
                                  (item) =>
                                    item?.gameType?.toUpperCase() ==
                                    selectedFancy
                                )
                                ?.oddDetailsDTOS?.map((item) => (
                                  <tbody
                                    _ngcontent-ng-c3599551908=""
                                    className="ng-star-inserted"
                                  >
                                    <tr
                                      _ngcontent-ng-c3599551908=""
                                      className="odd-row ng-star-inserted"
                                    >
                                      <td
                                        _ngcontent-ng-c3599551908=""
                                        className="team-name"
                                      >
                                        <a
                                          _ngcontent-ng-c3599551908=""
                                          className="title fancy-title"
                                        >
                                          {item?.runnerName}
                                        </a>
                                        {/**/}
                                        <span
                                          _ngcontent-ng-c3599551908=""
                                          className="ng-star-inserted"
                                        >
                                          <b
                                            _ngcontent-ng-c3599551908=""
                                            className={
                                              getExposure(
                                                item,
                                                matchOdds?.fancyOdds?.find(
                                                  (item) =>
                                                    item?.gameType?.toUpperCase() ==
                                                    selectedFancy
                                                ),
                                                "FANCY"
                                              )?.exposure > 0
                                                ? "plus-book"
                                                : "minus-book"
                                            }
                                          >
                                            {getExposure(
                                              item,
                                              matchOdds?.fancyOdds?.find(
                                                (item) =>
                                                  item?.gameType?.toUpperCase() ==
                                                  selectedFancy
                                              ),
                                              "FANCY"
                                            )?.exposure && (
                                              <i
                                                _ngcontent-ng-c3599551908=""
                                                className="fa fa-arrow-right"
                                              />
                                            )}{" "}
                                            {
                                              getExposure(
                                                item,
                                                matchOdds?.fancyOdds?.find(
                                                  (item) =>
                                                    item?.gameType?.toUpperCase() ==
                                                    selectedFancy
                                                ),
                                                "FANCY"
                                              )?.exposure
                                            }
                                          </b>
                                        </span>
                                        {/**/}
                                      </td>
                                      <td
                                        _ngcontent-ng-c3599551908=""
                                        className="valign-middle"
                                      >
                                        <button
                                          _ngcontent-ng-c3599551908=""
                                          data-bs-toggle="modal"
                                          data-bs-target="#fancyPLModal"
                                          onClick={() =>
                                            setShowMarket(item.marketId)
                                          }
                                          className="book-btn btn btn-primary"
                                        >
                                          Book
                                        </button>
                                      </td>
                                      <td
                                        _ngcontent-ng-c3599551908=""
                                        id="fancy"
                                        className="lay-1 count"
                                        style={{
                                          paddingLeft: 0,
                                          paddingRight: 0,
                                        }}
                                      >
                                        <a
                                          _ngcontent-ng-c3599551908=""
                                          className="not-allowed"
                                        >
                                          {" "}
                                          {item?.back1}{" "}
                                          <span _ngcontent-ng-c3599551908="">
                                            {item?.backSize1}
                                          </span>
                                        </a>
                                      </td>
                                      <td
                                        _ngcontent-ng-c3599551908=""
                                        id="fancy"
                                        className="back-1 count p-rltv"
                                        style={{
                                          paddingLeft: 0,
                                          paddingRight: 0,
                                        }}
                                      >
                                        {/**/}
                                        {item?.status?.toLowerCase() != "" && (
                                          <div
                                            _ngcontent-ng-c3599551908=""
                                            className="spnd-fncy ng-star-inserted"
                                          >
                                            <span
                                              _ngcontent-ng-c3599551908=""
                                              className="stats-text"
                                            >
                                              {item?.status}
                                            </span>
                                          </div>
                                        )}
                                        {/**/}
                                        <a
                                          _ngcontent-ng-c3599551908=""
                                          className="not-allowed"
                                        >
                                          {item?.lay1}{" "}
                                          <span _ngcontent-ng-c3599551908="">
                                            {item?.laySize1}
                                          </span>
                                        </a>
                                      </td>
                                      <td
                                        _ngcontent-ng-c3599551908=""
                                        className="min-max hidden-xs fancyminmaxValue"
                                      >
                                        100-25000
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}

                              {/**/}
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {/**/}
                {/**/}
              </div>
              {/**/}
              <div
                _ngcontent-ng-c3599551908=""
                className="col-lg-5 col-sm-12 analysis-sec col-12 right-section p-0"
              >
                <div
                  _ngcontent-ng-c3599551908=""
                  className="card mb-1 mobile-stream d-block"
                >
                  <div
                    onClick={() => setIsLiveTvOpen(!isLiveTvOpen)}
                    _ngcontent-ng-c3599551908=""
                    className="card-header card-accrdn card-matchodds"
                  >
                    <strong _ngcontent-ng-c3599551908="">
                      Live Streaming{" "}
                    </strong>
                  </div>
                  {channelId && isLiveTvOpen && (
                    <iframe
                      style={{
                        width: "100%",
                        height: "50vh",
                      }}
                      src={`https://video.proexch.in/tv/static?chid=${channelId}`}
                    />
                  )}
                </div>
                <div
                  _ngcontent-ng-c3599551908=""
                  className="card mb-1 mobile-stream"
                >
                  <div
                    _ngcontent-ng-c3599551908=""
                    className="card-header card-accrdn card-matchodds"
                  >
                    <strong _ngcontent-ng-c3599551908="">Score Card</strong>
                  </div>
                  <div
                    _ngcontent-ng-c3599551908=""
                    className="card-body"
                    style={{ padding: 0 }}
                  >
                    {matchDetails?.gameId && (
                      <iframe
                        style={{
                          width: "100%",
                        }}
                        src={`https://score.proexch.in/#/score1/${matchDetails?.gameId}`}
                      />
                    )}
                  </div>
                </div>
                <div _ngcontent-ng-c3599551908="" className="card open-bet">
                  <div
                    _ngcontent-ng-c3599551908=""
                    className="card-header card-accrdn card-matchodds"
                  >
                    <strong _ngcontent-ng-c3599551908=""> Book </strong>
                  </div>
                  <div
                    _ngcontent-ng-c3599551908=""
                    aria-expanded="true"
                    aria-hidden="false"
                    className="card-body open-bet-btns p-2 collapse in show"
                    style={{
                      overflow: "visible",
                      height: "auto",
                      display: "block",
                    }}
                  >
                    <div _ngcontent-ng-c3599551908="" className="row">
                      <div
                        _ngcontent-ng-c3599551908=""
                        className="col-6 col-sm-6 col-xl mb-3 mb-xl-0"
                      >
                        <button
                          _ngcontent-ng-c3599551908=""
                          role="button"
                          data-bs-toggle="modal"
                          data-bs-target="#masterBook"
                          onClick={() => setBookOpen("MASTER")}
                          className="btn btn-primary"
                        >
                          {" "}
                          Master Book
                        </button>
                      </div>
                      <div
                        _ngcontent-ng-c3599551908=""
                        className="col-6 col-sm-6 col-xl mb-3 mb-xl-0"
                      >
                        <button
                          _ngcontent-ng-c3599551908=""
                          data-bs-toggle="modal"
                          onClick={() => setBookOpen("USER")}
                          data-bs-target="#masterBook"
                          className="btn btn-primary"
                        >
                          {" "}
                          User Book
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c3599551908="" className="card open-bet">
                  <div
                    _ngcontent-ng-c3599551908=""
                    className="card-header card-accrdn card-matchodds"
                  >
                    <div _ngcontent-ng-c3599551908="" className="mt-2">
                      <strong
                        _ngcontent-ng-c3599551908=""
                        style={{ float: "left" }}
                      >
                        {" "}
                        Live Bet{" "}
                      </strong>
                      <div
                        _ngcontent-ng-c3599551908=""
                        className="form-check form-check-inline ms-2"
                        style={{ float: "left" }}
                      >
                        <label
                          _ngcontent-ng-c3599551908=""
                          className="switch switch-label switch-primary switch-sm me-2"
                        >
                          <input
                            _ngcontent-ng-c3599551908=""
                            type="checkbox"
                            onChange={(e) =>
                              setOptions({
                                ...options,
                                liveBet: e.target.checked,
                              })
                            }
                            value={options.liveBet}
                            className="switch-input ng-untouched ng-pristine ng-valid"
                          />
                          <span
                            _ngcontent-ng-c3599551908=""
                            data-checked="✓"
                            data-unchecked="✕"
                            className="switch-slider"
                          />
                        </label>
                      </div>
                    </div>
                    <div _ngcontent-ng-c3599551908="" className="mt-2">
                      <strong
                        _ngcontent-ng-c3599551908=""
                        style={{ float: "left" }}
                      >
                        {" "}
                        Partnership Book{" "}
                      </strong>
                      <div
                        _ngcontent-ng-c3599551908=""
                        className="form-check form-check-inline ms-2"
                        style={{ float: "left" }}
                      >
                        <label
                          _ngcontent-ng-c3599551908=""
                          className="switch switch-label switch-primary switch-sm me-2"
                        >
                          <input
                            _ngcontent-ng-c3599551908=""
                            type="checkbox"
                            onChange={(e) =>
                              setOptions({
                                ...options,
                                partnershipBook: e.target.checked,
                              })
                            }
                            value={options.partnershipBook}
                            className="switch-input ng-untouched ng-pristine ng-valid"
                          />
                          <span
                            _ngcontent-ng-c3599551908=""
                            data-checked="✓"
                            data-unchecked="✕"
                            className="switch-slider"
                          />
                        </label>
                      </div>
                    </div>
                    <a
                      _ngcontent-ng-c3599551908=""
                      data-bs-toggle="modal"
                      data-bs-target="#modalCreditReference"
                      className="viewMore"
                    >
                      {" "}
                      View More{" "}
                    </a>
                  </div>
                  <div
                    _ngcontent-ng-c3599551908=""
                    aria-expanded="true"
                    aria-hidden="false"
                    className="card-body open-bet-btns livebetbody collapse in show"
                    style={{
                      overflow: "visible",
                      height: "auto",
                      display: "block",
                    }}
                  >
                    {/**/}
                    {clientBets.length == 0 && (
                      <div
                        _ngcontent-ng-c3599551908=""
                        className="ng-star-inserted"
                      >
                        <p _ngcontent-ng-c3599551908="" className="text-center">
                          There are no any bet.
                        </p>
                      </div>
                    )}
                    <div
                      _ngcontent-ng-c3599551908=""
                      aria-expanded="true"
                      aria-hidden="false"
                      className="card-body open-bet-btns livebetbody collapse in show"
                      style={{
                        overflow: "visible",
                        height: "auto",
                        display: "block",
                      }}
                    >
                      <app-partnership-live-bets
                        _ngcontent-ng-c3599551908=""
                        _nghost-ng-c1486327317=""
                        className="ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1486327317=""
                          className="bet_slip ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1486327317=""
                            id="txnList"
                            name="txn"
                            className="slip-list"
                          >
                            <ul
                              _ngcontent-ng-c1486327317=""
                              id="backSlipHeader"
                              className="matched-head"
                            >
                              <li
                                _ngcontent-ng-c1486327317=""
                                className="col-bet"
                              >
                                Market Name
                              </li>
                              <li
                                _ngcontent-ng-c1486327317=""
                                className="col-odd"
                              >
                                Odds
                              </li>
                              <li
                                _ngcontent-ng-c1486327317=""
                                className="col-stake"
                              >
                                Stake
                              </li>
                              <li
                                _ngcontent-ng-c1486327317=""
                                className="col-profit"
                              >
                                Username
                              </li>
                            </ul>
                            {options.liveBet &&
                              clientBets?.map((item) => (
                                <dl
                                  _ngcontent-ng-c1486327317=""
                                  id="txn_304146967"
                                  className={`showInfo slip-back slip-${
                                    !item?.back ? "lay" : ""
                                  } ng-star-inserted`}
                                  style={{ display: "block" }}
                                >
                                  <dd
                                    _ngcontent-ng-c1486327317=""
                                    id="betInfo"
                                    className="refer refershow"
                                  >
                                    <span
                                      _ngcontent-ng-c1486327317=""
                                      id="placeDate"
                                    >
                                      Time:
                                      {moment(item?.placeDate + "Z")
                                        .utc()
                                        .local()
                                        .format("DD/MM/YY hh:mm:ss A")}
                                    </span>
                                  </dd>
                                  <dt _ngcontent-ng-c1486327317="">
                                    <span
                                      _ngcontent-ng-c1486327317=""
                                      className="slip-type ng-star-inserted"
                                    >
                                      {item?.back ? "BACK" : "LAY"}
                                    </span>
                                    <span
                                      _ngcontent-ng-c1486327317=""
                                      className="ellipsis-text"
                                    >
                                      {item?.runnerName}
                                    </span>
                                    <span
                                      _ngcontent-ng-c1486327317=""
                                      id="marketName"
                                      className="short-amount"
                                    >
                                      {item?.marketName}
                                    </span>
                                  </dt>
                                  <dd
                                    _ngcontent-ng-c1486327317=""
                                    id="price"
                                    className="col-odd"
                                  >
                                    {/**/}
                                    {item?.rate}{" "}
                                  </dd>
                                  <dd
                                    _ngcontent-ng-c1486327317=""
                                    id="size"
                                    className="col-stake"
                                  >
                                    {" "}
                                    {item?.amount}
                                  </dd>
                                  <dd
                                    _ngcontent-ng-c1486327317=""
                                    id="profitLiability"
                                    className="col-profit ellipsis-text"
                                  >
                                    <a
                                      _ngcontent-ng-c1486327317=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalparentList"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {" "}
                                      {item?.userName}{" "}
                                    </a>
                                  </dd>
                                </dl>
                              ))}
                            {/**/}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1486327317=""
                          id="modalparentList"
                          tabIndex={-1}
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                          className="modal fade add-user"
                        >
                          <div
                            _ngcontent-ng-c1486327317=""
                            className="modal-dialog"
                          >
                            <div
                              _ngcontent-ng-c1486327317=""
                              className="modal-content"
                            >
                              <div
                                _ngcontent-ng-c1486327317=""
                                className="modal-header bg-primary"
                              >
                                <h5
                                  _ngcontent-ng-c1486327317=""
                                  className="modal-title"
                                >
                                  Parent List
                                </h5>
                                <button
                                  _ngcontent-ng-c1486327317=""
                                  type="button"
                                  className="close"
                                >
                                  <span
                                    _ngcontent-ng-c1486327317=""
                                    aria-hidden="true"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    ×
                                  </span>
                                </button>
                              </div>
                              <div
                                _ngcontent-ng-c1486327317=""
                                className="modal-body text-center"
                              >
                                <table
                                  _ngcontent-ng-c1486327317=""
                                  className="table-striped table-bordered table"
                                >
                                  <tbody _ngcontent-ng-c1486327317="">
                                    {/**/}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </app-partnership-live-bets>
                      {/**/}
                      {/**/}
                    </div>

                    {/**/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c3599551908=""
            id="modalCreditReference"
            tabIndex={-1}
            aria-labelledby="yourModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c3599551908="" className="modal-dialog modal-xl">
              <div _ngcontent-ng-c3599551908="" className="modal-content">
                <div
                  _ngcontent-ng-c3599551908=""
                  className="modal-header bg-primary"
                >
                  <h4
                    _ngcontent-ng-c3599551908=""
                    id="modal-basic-title"
                    className="modal-title"
                  >
                    View More Bet
                  </h4>
                  <button
                    _ngcontent-ng-c3599551908=""
                    type="button"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    className="btn close"
                  >
                    <span _ngcontent-ng-c3599551908="" aria-hidden="true">
                      ×
                    </span>
                  </button>
                </div>
                <div _ngcontent-ng-c3599551908="" className="modal-body">
                  <div _ngcontent-ng-c3599551908="" className="modal-body">
                    <app-view-more-bet
                      _ngcontent-ng-c3599551908=""
                      _nghost-ng-c3059760819=""
                      className="ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c3059760819=""
                        className="table-responsive"
                      >
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className=""
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                          <div
                            id="DataTables_Table_0_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className=""
                                placeholder=""
                                aria-controls="DataTables_Table_0"
                              />
                            </label>
                          </div>
                          <table
                            _ngcontent-ng-c3059760819=""
                            datatable=""
                            role="table"
                            aria-busy="false"
                            aria-colcount={6}
                            className="table table-bordered table-striped dataTable no-footer"
                            id="DataTables_Table_0"
                            aria-describedby="DataTables_Table_0_info"
                          >
                            <thead _ngcontent-ng-c3059760819="" role="rowgroup">
                              <tr _ngcontent-ng-c3059760819="" role="row">
                                <th
                                  _ngcontent-ng-c3059760819=""
                                  className="sorting sorting_desc"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_0"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="UserName: activate to sort column ascending"
                                  aria-sort="descending"
                                >
                                  UserName
                                </th>
                                <th
                                  _ngcontent-ng-c3059760819=""
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_0"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Nation: activate to sort column ascending"
                                >
                                  Nation
                                </th>
                                <th
                                  _ngcontent-ng-c3059760819=""
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_0"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Amount: activate to sort column ascending"
                                >
                                  Amount
                                </th>
                                <th
                                  _ngcontent-ng-c3059760819=""
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_0"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="User Rate: activate to sort column ascending"
                                >
                                  User Rate
                                </th>
                                <th
                                  _ngcontent-ng-c3059760819=""
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_0"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Place Date: activate to sort column ascending"
                                >
                                  Place Date
                                </th>
                                <th
                                  _ngcontent-ng-c3059760819=""
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_0"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Match Date: activate to sort column ascending"
                                >
                                  Match Date
                                </th>
                                <th
                                  _ngcontent-ng-c3059760819=""
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_0"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Game Type: activate to sort column ascending"
                                >
                                  Game Type
                                </th>
                              </tr>
                            </thead>
                            <tbody _ngcontent-ng-c3059760819="">
                              {clientBets?.map((item) => (
                                <tr
                                  _ngcontent-ng-c3059760819=""
                                  className={`${
                                    item?.back ? "back" : "lay"
                                  } ng-star-inserted`}
                                >
                                  <td _ngcontent-ng-c3059760819="">
                                    <a
                                      _ngcontent-ng-c3059760819=""
                                      className="plLink"
                                    >
                                      {item?.userName}
                                    </a>
                                  </td>
                                  <td _ngcontent-ng-c3059760819="">
                                    {item?.runnerName}{" "}
                                  </td>
                                  <td _ngcontent-ng-c3059760819="">
                                    {item?.amount}
                                  </td>
                                  <td _ngcontent-ng-c3059760819="">
                                    {" "}
                                    {item?.rate}
                                  </td>
                                  <td _ngcontent-ng-c3059760819="">
                                    {moment(item?.placeDate + "Z")
                                      .utc()
                                      .local()
                                      .format("DD/MM/YY hh:mm:ss A")}
                                  </td>
                                  <td _ngcontent-ng-c3059760819="">
                                    {" "}
                                    {moment(item?.placeDate + "Z")
                                      .utc()
                                      .local()
                                      .format("DD/MM/YY hh:mm:ss A")}
                                  </td>
                                  <td _ngcontent-ng-c3059760819="">
                                    {item?.marketName}
                                  </td>
                                </tr>
                              ))}
                              {/**/}
                              {/**/}
                              <tr className="odd">
                                <td
                                  valign="top"
                                  colSpan={7}
                                  className="dataTables_empty"
                                >
                                  No matching records found
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to 1 of 1 entries
                          </div>
                          <div
                            className="dataTables_paginate paging_full_numbers"
                            id="DataTables_Table_0_paginate"
                          >
                            <a
                              className="paginate_button first disabled"
                              aria-controls="DataTables_Table_0"
                              aria-disabled="true"
                              role="link"
                              data-dt-idx="first"
                              tabIndex={-1}
                              id="DataTables_Table_0_first"
                            >
                              First
                            </a>
                            <a
                              className="paginate_button previous disabled"
                              aria-controls="DataTables_Table_0"
                              aria-disabled="true"
                              role="link"
                              data-dt-idx="previous"
                              tabIndex={-1}
                              id="DataTables_Table_0_previous"
                            >
                              Previous
                            </a>
                            <span>
                              <a
                                className="paginate_button current"
                                aria-controls="DataTables_Table_0"
                                role="link"
                                aria-current="page"
                                data-dt-idx={0}
                                tabIndex={0}
                              >
                                1
                              </a>
                            </span>
                            <a
                              className="paginate_button next disabled"
                              aria-controls="DataTables_Table_0"
                              aria-disabled="true"
                              role="link"
                              data-dt-idx="next"
                              tabIndex={-1}
                              id="DataTables_Table_0_next"
                            >
                              Next
                            </a>
                            <a
                              className="paginate_button last disabled"
                              aria-controls="DataTables_Table_0"
                              aria-disabled="true"
                              role="link"
                              data-dt-idx="last"
                              tabIndex={-1}
                              id="DataTables_Table_0_last"
                            >
                              Last
                            </a>
                          </div>
                        </div>
                        {/**/}
                      </div>
                      <div
                        _ngcontent-ng-c3059760819=""
                        className="custom-modal-overlay d-none"
                      >
                        <div
                          _ngcontent-ng-c3059760819=""
                          className="custom-modal modal-xl"
                        >
                          <div
                            _ngcontent-ng-c3059760819=""
                            className="modal-header bg-primary"
                          >
                            <h4
                              _ngcontent-ng-c3059760819=""
                              id="modal-basic-title"
                              className="modal-title"
                            >
                              Parent List
                            </h4>
                            <button
                              _ngcontent-ng-c3059760819=""
                              type="button"
                              aria-label="Close"
                              data-bs-dismiss="modal"
                              className="btn close"
                            >
                              <span
                                _ngcontent-ng-c3059760819=""
                                aria-hidden="true"
                              >
                                ×
                              </span>
                            </button>
                          </div>
                          <div
                            _ngcontent-ng-c3059760819=""
                            className="custom-modal-body"
                          >
                            <table
                              _ngcontent-ng-c3059760819=""
                              className="table-striped table-bordered table"
                            >
                              <tbody _ngcontent-ng-c3059760819="">{/**/}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </app-view-more-bet>
                    {/**/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c2049722135=""
            id="masterBook"
            tabIndex={-1}
            aria-labelledby="yourModalLabel"
            className="modal fade show"
            aria-modal="true"
            role="dialog"
            style={{ display: bookOpen ? "block" : "none" }}
          >
            <div _ngcontent-ng-c2049722135="" className="modal-dialog modal-lg">
              <div _ngcontent-ng-c2049722135="" className="modal-content">
                <div
                  _ngcontent-ng-c2049722135=""
                  className="modal-header bg-primary"
                >
                  <h4
                    _ngcontent-ng-c2049722135=""
                    id="modal-basic-title"
                    className="modal-title"
                  >
                    Market List
                  </h4>
                  <button
                    onClick={() => {
                      setBookOpen(null);
                      setSelectedBook(null);
                    }}
                    _ngcontent-ng-c2049722135=""
                    type="button"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    className="close"
                  >
                    <span _ngcontent-ng-c2049722135="" aria-hidden="true">
                      ×
                    </span>
                  </button>
                </div>
                <div _ngcontent-ng-c2049722135="" className="modal-body">
                  <table
                    _ngcontent-ng-c2049722135=""
                    cellPadding={0}
                    cellSpacing={0}
                    className="table table-hover table-bordered mb-0"
                  >
                    <tbody _ngcontent-ng-c2049722135="">
                      <tr
                        _ngcontent-ng-c2049722135=""
                        className="ng-star-inserted"
                        onClick={() => setSelectedBook("MATCH_ODDS")}
                      >
                        <td _ngcontent-ng-c2049722135="">
                          <a
                            _ngcontent-ng-c2049722135=""
                            className="match-name"
                          >
                            <b _ngcontent-ng-c2049722135="">Match Odds</b>
                          </a>
                        </td>
                      </tr>
                      <tr
                        _ngcontent-ng-c2049722135=""
                        className="ng-star-inserted"
                        onClick={() => setSelectedBook("BOOKMAKER_ODDS_1")}
                      >
                        <td _ngcontent-ng-c2049722135="">
                          <a
                            _ngcontent-ng-c2049722135=""
                            className="match-name"
                          >
                            <b _ngcontent-ng-c2049722135="">Bookmaker</b>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {bookOpen && eventId && selectedBook && (
            <div
              _ngcontent-ng-c2049722135=""
              className="custom-modal-overlay d-block"
            >
              <div
                _ngcontent-ng-c2049722135=""
                className="custom-modal modal-lg"
              >
                <div
                  _ngcontent-ng-c2049722135=""
                  className="modal-header bg-primary"
                >
                  <h4
                    _ngcontent-ng-c2049722135=""
                    id="modal-basic-title"
                    className="modal-title"
                  >
                    {bookOpen} Book
                  </h4>
                  <button
                    _ngcontent-ng-c2049722135=""
                    type="button"
                    className="close"
                    onClick={
                      () => {
                        setBookOpen(null);
                        setSelectedBook(null);
                      }
                      // setBookOpen(false)
                    }
                  >
                    <span _ngcontent-ng-c2049722135="" aria-hidden="true">
                      ×
                    </span>
                  </button>
                </div>
                <div
                  _ngcontent-ng-c2049722135=""
                  className="custom-modal-body table-responsive"
                >
                  <table
                    _ngcontent-ng-c2049722135=""
                    className="table table-bordered mb-0"
                  >
                    <thead _ngcontent-ng-c2049722135="" className="bg-default">
                      <tr _ngcontent-ng-c2049722135="">
                        <th
                          _ngcontent-ng-c2049722135=""
                          className="text-captialzie text-center align-middle ng-star-inserted"
                        >
                          username
                        </th>
                        <th
                          _ngcontent-ng-c2049722135=""
                          className="text-captialzie text-center align-middle ng-star-inserted"
                        >
                          role
                        </th>
                        {skyBook?.length > 0 &&
                          skyBook[0]?.runnerBookResponseDTOS?.map((item) => (
                            <>
                              <th
                                _ngcontent-ng-c2049722135=""
                                className="text-captialzie text-center align-middle ng-star-inserted"
                              >
                                {item.runnerName}
                              </th>
                            </>
                          ))}
                        {/**/}
                      </tr>
                    </thead>
                    <tbody _ngcontent-ng-c2049722135="">
                      {skyBook?.map((item) => (
                        <tr
                          _ngcontent-ng-c2049722135=""
                          className="ng-star-inserted"
                        >
                          <td
                            _ngcontent-ng-c2049722135=""
                            className="text-center align-middle ng-star-inserted"
                          >
                            <a
                              _ngcontent-ng-c2049722135=""
                              style={{ cursor: "pointer" }}
                              className="userColr ng-star-inserted"
                            >
                              {item?.userName}
                            </a>
                            {/**/}
                            {/**/}
                          </td>
                          {/**/}
                          <td
                            _ngcontent-ng-c2049722135=""
                            className="text-center align-middle ng-star-inserted"
                          >
                            {/**/}
                            <span
                              _ngcontent-ng-c2049722135=""
                              className="roleText ng-star-inserted"
                            >
                              {item?.userType}
                            </span>
                            {/**/}
                          </td>
                          {/**/}
                          {item?.runnerBookResponseDTOS?.map((pnl) => (
                            <td
                              _ngcontent-ng-c2049722135=""
                              className="text-center align-middle ng-star-inserted"
                            >
                              {/**/}
                              <span
                                _ngcontent-ng-c2049722135=""
                                className={`${
                                  pnl?.pandL > 0 ? "plus" : "minus"
                                }-book ng-star-inserted`}
                              >
                                {pnl?.pandL}
                              </span>
                              {/**/}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div
            _ngcontent-ng-c3599551908=""
            id="masterBook"
            tabIndex={-1}
            aria-labelledby="yourModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c3599551908="" className="modal-dialog modal-lg">
              <div _ngcontent-ng-c3599551908="" className="modal-content">
                <div
                  _ngcontent-ng-c3599551908=""
                  className="modal-header bg-primary"
                >
                  <h4
                    _ngcontent-ng-c3599551908=""
                    id="modal-basic-title"
                    className="modal-title"
                  >
                    {selectedTab == "book" ? "Master Book" : "User Book"}
                  </h4>
                  <button
                    _ngcontent-ng-c3599551908=""
                    type="button"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    className="close"
                  >
                    <span _ngcontent-ng-c3599551908="" aria-hidden="true">
                      ×
                    </span>
                  </button>
                </div>
                <div
                  _ngcontent-ng-c3599551908=""
                  className="custom-modal-body table-responsive"
                >
                  <table
                    _ngcontent-ng-c3599551908=""
                    className="table table-bordered mb-0"
                  >
                    <thead _ngcontent-ng-c3599551908="" className="bg-default">
                      <tr _ngcontent-ng-c3599551908="">
                        {userBook?.columns?.map((book) => (
                          <th
                            _ngcontent-ng-c3599551908=""
                            className="text-captialzie text-center align-middle ng-star-inserted"
                          >
                            {book?.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody _ngcontent-ng-c3599551908="">
                      {userBook?.rows?.map((bet) => (
                        <tr
                          _ngcontent-ng-c3599551908=""
                          className="ng-star-inserted"
                        >
                          {userBook?.columns?.map((column) => (
                            <td
                              _ngcontent-ng-c3599551908=""
                              style={{
                                color:
                                  bet[column?.id] > 0
                                    ? "green !important"
                                    : "red",
                              }}
                              className="text-center align-middle ng-star-inserted"
                            >
                              <a
                                _ngcontent-ng-c3599551908=""
                                className="text-black ng-star-inserted"
                              >
                                {bet[column?.id]}
                              </a>
                              {/**/}
                              {/**/}
                            </td>
                          ))}
                        </tr>
                      ))}
                      {/**/}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c3599551908=""
            className="custom-modal-overlay d-none"
          >
            <div _ngcontent-ng-c3599551908="" className="custom-modal modal-lg">
              <div
                _ngcontent-ng-c3599551908=""
                className="modal-header bg-primary"
              >
                <h4
                  _ngcontent-ng-c3599551908=""
                  id="modal-basic-title"
                  className="modal-title"
                >
                  {" "}
                  Book
                </h4>
                <button
                  _ngcontent-ng-c3599551908=""
                  type="button"
                  className="close"
                >
                  <span _ngcontent-ng-c3599551908="" aria-hidden="true">
                    ×
                  </span>
                </button>
              </div>
              <div
                _ngcontent-ng-c3599551908=""
                className="custom-modal-body table-responsive"
              >
                <table
                  _ngcontent-ng-c3599551908=""
                  className="table table-bordered mb-0"
                >
                  <thead _ngcontent-ng-c3599551908="" className="bg-default">
                    <tr _ngcontent-ng-c3599551908="">{/**/}</tr>
                  </thead>
                  <tbody _ngcontent-ng-c3599551908="">{/**/}</tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c3599551908=""
            id="fancyPLModal"
            tabIndex={-1}
            aria-labelledby="fancyPLModalLabel"
            aria-hidden="true"
            className="modal fade"
          >
            <div _ngcontent-ng-c3599551908="" className="modal-dialog modal-md">
              <div _ngcontent-ng-c3599551908="" className="modal-content">
                <div
                  _ngcontent-ng-c3599551908=""
                  className="modal-header bg-primary"
                >
                  <h3
                    _ngcontent-ng-c3599551908=""
                    id="exposureModalLabel"
                    className="modal-title"
                  >
                    Book
                  </h3>
                  <button
                    _ngcontent-ng-c3599551908=""
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="close"
                  >
                    <span _ngcontent-ng-c3599551908="" aria-hidden="true">
                      ×
                    </span>
                  </button>
                </div>
                <div
                  _ngcontent-ng-c3599551908=""
                  className="modal-body scrollBodyRule"
                >
                  <div _ngcontent-ng-c3599551908="">
                    <table
                      _ngcontent-ng-c3599551908=""
                      className="table table-bordered fancybooktable"
                    >
                      <thead
                        _ngcontent-ng-c3599551908=""
                        className="border fancyhead"
                      >
                        <tr _ngcontent-ng-c3599551908="">
                          <th
                            _ngcontent-ng-c3599551908=""
                            className="border text-center"
                          >
                            Run
                          </th>
                          <th
                            _ngcontent-ng-c3599551908=""
                            className="border text-center"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody _ngcontent-ng-c3599551908="">
                        {showMarket &&
                          getLadderForMarket(showMarket)?.map((item) => (
                            <tr
                              _ngcontent-ng-c3599551908=""
                              className="border text-center"
                            >
                              <td
                                _ngcontent-ng-c3599551908=""
                                className="border text-center"
                              >
                                {item?.selectionId}
                              </td>
                              <td
                                _ngcontent-ng-c3599551908=""
                                style={{
                                  color: item?.exposure > 0 ? "green" : "red",
                                }}
                                className="border text-center"
                              >
                                {item?.exposure}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c3599551908=""
            id="exampleModal1"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLable"
            aria-hidden="true"
            className="modal fade"
          >
            <div
              _ngcontent-ng-c3599551908=""
              role="document"
              className="modal-dialog modal-md"
            >
              <div _ngcontent-ng-c3599551908="" className="modal-content">
                <div
                  _ngcontent-ng-c3599551908=""
                  className="modal-content rules-wrap"
                >
                  <div _ngcontent-ng-c3599551908="" className="rules-content">
                    <div _ngcontent-ng-c3599551908="" />
                  </div>
                  <ul _ngcontent-ng-c3599551908="" className="btn-wrap break">
                    <li _ngcontent-ng-c3599551908="">
                      <a
                        _ngcontent-ng-c3599551908=""
                        data-bs-dismiss="modal"
                        className="btnok"
                      >
                        OK
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </app-gamedetails>
        {/**/}
      </div>
    </div>
  );
}
