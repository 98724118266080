import { Box, Button, Input, Select, Td, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  getBetHistoryAPI,
  getParentNamesAPI,
  newBetGetHistoryAPI,
  searchUserAPI,
} from "../../service/AuthService";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

export default function BetHistory({ userName }) {
  const [data, setData] = React.useState([]);
  const { state } = useLocation();
  const [page, setPage] = React.useState({
    page: 0,
    size: 50,
    total: 0,
  });
  const [payload, setPayload] = React.useState({
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    search: "",
    page: 1,
    size: 50,
    sport: "CRICKET",
    betListType: "SETTLE",
  });
  const [parentList, setParentList] = React.useState({});
  const [parentListOpen, setParentListOpen] = React.useState(false);
  const getBetHistory = async () => {
    const { response } = await newBetGetHistoryAPI({
      search: payload.search,
      from: moment(payload.startDate),
      to: moment(payload.endDate),
      page: page.page,
      size: page.size,
      sport: payload.sport,
      betListType: payload.betListType,
      search: payload?.search ? payload?.search : userName,
    });
    if (response) {
      setPage({
        page: response?.number,
        size: response?.size,
        total: response?.totalElements,
        totalPages: response?.totalPages,
      });
      setData(response.content);
    }
  };
  const getParentName = async () => {
    const { response } = await getParentNamesAPI(parentListOpen);
    if (response) {
      setParentList(response);
    }
  };
  useEffect(() => {
    getParentName();
  }, [parentListOpen]);
  useEffect(() => {
    getBetHistory();
  }, [page.size, page.page, payload.sport, payload.betListType]);
  return (
    <app-bet-history _nghost-ng-c2274924760="" className="ng-star-inserted">
      {parentListOpen && (
        <div
          _ngcontent-ng-c2822817509=""
          id="modalparentList"
          tabIndex={-1}
          aria-labelledby="yourModalLabel"
          className="modal fade show"
          aria-modal="true"
          role="dialog"
          style={{ display: "block" }}
        >
          <div _ngcontent-ng-c2822817509="" className="modal-dialog modal-sm">
            <div _ngcontent-ng-c2822817509="" className="modal-content">
              <div
                _ngcontent-ng-c2822817509=""
                className="modal-header bg-primary"
              >
                <h4
                  _ngcontent-ng-c2822817509=""
                  id="modal-basic-title"
                  className="modal-title"
                >
                  Parent List
                </h4>
                <button
                  _ngcontent-ng-c2822817509=""
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  className="close"
                  onClick={() => setParentListOpen(false)}
                >
                  <span _ngcontent-ng-c2822817509="" aria-hidden="true">
                    ×
                  </span>
                </button>
              </div>
              <div
                _ngcontent-ng-c2822817509=""
                className="modal-body text-center"
              >
                <ul _ngcontent-ng-c2822817509="" className="list-group">
                  {parentList &&
                    Object.values(parentList)?.length > 0 &&
                    Object.keys(parentList)?.sort(
                      (a, b) => b - a
                    )?.map((item, index) => (
                      <li
                        _ngcontent-ng-c2822817509=""
                        className="list-group-item ng-star-inserted"
                      >
                        <b _ngcontent-ng-c2822817509=""> {parentList[item]} </b>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div _ngcontent-ng-c2274924760="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c2274924760="" className="row">
          <div _ngcontent-ng-c2274924760="" className="col-sm-12 col-md-12">
            <div _ngcontent-ng-c2274924760="" className="card card-report">
              <div _ngcontent-ng-c2274924760="" className="card-body row p-0">
                {/**/}
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group mb-0">
                    <label
                      _ngcontent-ng-c2274924760=""
                      htmlFor="typechoose"
                      className="mb-2 marginLabel"
                    >
                      Choose Type
                    </label>
                    <div _ngcontent-ng-c2274924760="" className="input-group">
                      <select
                        _ngcontent-ng-c2274924760=""
                        name="type"
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            betListType: e.target.value,
                          });
                        }}
                        id="typechoose"
                        className="form-control form-select ng-untouched ng-pristine ng-valid"
                      >
                        <option _ngcontent-ng-c2274924760="" value="SETTLE">
                          Settle
                        </option>
                        <option _ngcontent-ng-c2274924760="" value="VOID">
                          Void
                        </option>
                        <option _ngcontent-ng-c2274924760="" value="UNSETTLE">
                          Unsettled
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group mb-0">
                    <label
                      _ngcontent-ng-c2274924760=""
                      htmlFor="choosesport"
                      className="mb-2 marginLabel"
                    >
                      Choose Sport
                    </label>
                    <div _ngcontent-ng-c2274924760="" className="input-group">
                      <select
                        _ngcontent-ng-c2274924760=""
                        name="type"
                        id="choosesport"
                        value={payload.sport}
                        onChange={(e) =>
                          setPayload({ ...payload, sport: e.target.value })
                        }
                        className="sel form-control form-select ng-untouched ng-pristine ng-valid"
                      >
                        <option
                          _ngcontent-ng-c2274924760=""
                          value=""
                          disabled=""
                        >
                          Select Sport
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"ALL"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          ALL{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"CRICKET"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Cricket{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"TENNIS"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Tennis{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"CASINO"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Casino{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"SOCCER"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Soccer{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={7}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Horse Racing{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={4339}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Greyhound Racing{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={27979456}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Kabaddi{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={7522}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Basketball{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={2378961}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Politics{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={66101}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Virtual Sports{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={66103}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Binary{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={66104}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Lottery{" "}
                        </option>
                        {/**/}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group mb-0">
                    <div
                      _ngcontent-ng-c2274924760=""
                      htmlFor="startDate"
                      className="mb-2 marginLabel"
                    >
                      From
                    </div>
                    <div _ngcontent-ng-c2274924760="" className="input-group">
                      <input
                        _ngcontent-ng-c2274924760=""
                        placeholder="Select Date"
                        name="mydate"
                        readOnly=""
                        type="date"
                        onChange={(e) =>
                          setPayload({ ...payload, from: e.target.value })
                        }
                        value={moment(payload?.from).format("YYYY-MM-DD")}
                        autoComplete="off"
                        className="form-control dateClass ng-untouched ng-pristine ng-valid"
                        style={{ padding: "0px !important" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group mb-0">
                    <div
                      _ngcontent-ng-c2274924760=""
                      htmlFor="endDate"
                      className="mb-2 marginLabel"
                    >
                      To
                    </div>
                    <input
                      _ngcontent-ng-c2274924760=""
                      placeholder="Select Date"
                      name="mydate"
                      readOnly=""
                      type="date"
                      onChange={(e) =>
                        setPayload({ ...payload, to: e.target.value })
                      }
                      value={moment(payload?.to).format("YYYY-MM-DD")}
                      autoComplete="off"
                      className="form-control dateClass ng-untouched ng-pristine ng-valid"
                      style={{ padding: "0px !important" }}
                    ></input>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group">
                    <div
                      _ngcontent-ng-c2274924760=""
                      htmlFor="type"
                      className="mobile-d-none"
                      style={{ width: "100%" }}
                    >
                      &nbsp;
                    </div>
                    <button
                      _ngcontent-ng-c2274924760=""
                      type="button"
                      onClick={getBetHistory}
                      className="btn btn-primary btnMrgn"
                    >
                      <strong _ngcontent-ng-c2274924760="">Get History</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-ng-c2274924760="" className="row">
          <div _ngcontent-ng-c2274924760="" className="col-sm-12 col-md-12">
            <div
              _ngcontent-ng-c2274924760=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c2274924760=""
                className="card-header account-detail-head"
              >
                {" "}
                Bet History{" "}
                <a
                  _ngcontent-ng-c2274924760=""
                  placement="right"
                  popover="Betting History is available online for the past 30 days."
                  triggers="click"
                >
                  <i
                    _ngcontent-ng-c2274924760=""
                    className="fa fa-question-circle"
                  />
                </a>
              </div>
              <div
                _ngcontent-ng-c2274924760=""
                className="card-body account-stat-body"
              >
                <div
                  _ngcontent-ng-c2274924760=""
                  className="table-responsive table table-striped"
                >
                  <div
                    id="DataTables_Table_27_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_27_length"
                    >
                      <label>
                        Show{" "}
                        <select
                          name="DataTables_Table_27_length"
                          aria-controls="DataTables_Table_27"
                          className=""
                          onChange={(e) =>
                            setPayload({ ...payload, size: e.target.value })
                          }
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                    <div
                      id="DataTables_Table_27_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        Search:
                        <input
                          type="search"
                          className=""
                          placeholder=""
                          value={payload.search}
                          onChange={(e) =>
                            setPayload({ ...payload, search: e.target.value })
                          }
                          aria-controls="DataTables_Table_27"
                        />
                      </label>
                    </div>
                    <table
                      _ngcontent-ng-c2274924760=""
                      datatable=""
                      role="table"
                      aria-busy="false"
                      aria-colcount={6}
                      className="table table-bordered table-striped dataTable no-footer"
                      id="DataTables_Table_27"
                      aria-describedby="DataTables_Table_27_info"
                    >
                      <thead _ngcontent-ng-c2274924760="" role="rowgroup">
                        <tr _ngcontent-ng-c2274924760="" role="row">
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting sorting_desc"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="descending"
                            aria-label="User Name: activate to sort column ascending"
                          >
                            User Name
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="SportName: activate to sort column ascending"
                          >
                            SportName
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Event: activate to sort column ascending"
                          >
                            Event
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Market: activate to sort column ascending"
                          >
                            Market
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Selection: activate to sort column ascending"
                          >
                            Selection
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Type: activate to sort column ascending"
                          >
                            Type
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Odds Req.: activate to sort column ascending"
                          >
                            Odds Req.
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Stack: activate to sort column ascending"
                          >
                            Stack
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Place Time: activate to sort column ascending"
                          >
                            Place Time
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            rowSpan={1}
                            colSpan={1}
                          >
                            Settle Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="odd">
                          <td
                            valign="top"
                            colSpan={9}
                            className="dataTables_empty"
                          >
                            No data available in table
                          </td>
                        </tr>
                      </tbody>
                      {/**/}
                      <tbody>
                        {data?.map((item, index) => (
                          <tr
                            _ngcontent-ng-c2274924760=""
                            role="row"
                            className={`odd ${
                              item?.betType == "BACK" ? "back" : "lay"
                            } ng-star-inserted`}
                          >
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                              onClick={() => {
                                setParentListOpen(item.userName);
                              }}
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              {item.userName}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {payload.sport}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.eventName}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.marketName}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.selectionName}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.betType}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.odd}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.amount}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {moment(item.placeTime + "Z").format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {moment(item.updatedTime + "Z").format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </td>
                          </tr>
                        ))}
                        {data?.length == 0 && (
                          <tr
                            _ngcontent-ng-c2274924760=""
                            className="odd ng-star-inserted"
                          >
                            <td
                              _ngcontent-ng-c2274924760=""
                              colSpan={10}
                              className="no-data-available"
                            >
                              No data!
                            </td>
                          </tr>
                        )}
                        {/**/}
                      </tbody>
                    </table>
                    <div
                      className="dataTables_info"
                      id="DataTables_Table_27_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {page.page * page.size + 1} to{" "}
                      {page.page * page.size + data.length} of {page.total}{" "}
                      entries
                    </div>
                    <div
                      className="dataTables_paginate paging_full_numbers"
                      id="DataTables_Table_27_paginate"
                    >
                      <a
                        className="paginate_button first disabled"
                        aria-controls="DataTables_Table_27"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="first"
                        tabIndex={-1}
                        onClick={() => {
                          setPage({ ...page, page: 0 });
                        }}
                        id="DataTables_Table_27_first"
                      >
                        First
                      </a>
                      <a
                        className="paginate_button previous disabled"
                        aria-controls="DataTables_Table_27"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="previous"
                        tabIndex={-1}
                        onClick={() => {
                          setPage({ ...page, page: page.page - 1 });
                        }}
                        id="DataTables_Table_27_previous"
                      >
                        Previous
                      </a>
                      <span />
                      <a
                        className="paginate_button next disabled"
                        aria-controls="DataTables_Table_27"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="next"
                        tabIndex={-1}
                        onClick={() => {
                          setPage({ ...page, page: page.page + 1 });
                        }}
                        id="DataTables_Table_27_next"
                      >
                        Next
                      </a>
                      <a
                        className="paginate_button last disabled"
                        aria-controls="DataTables_Table_27"
                        aria-disabled="true"
                        role="link"
                        onClick={() => {
                          setPage({ ...page, page: page.totalPages - 1 });
                        }}
                        data-dt-idx="last"
                        tabIndex={-1}
                        id="DataTables_Table_27_last"
                      >
                        Last
                      </a>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-bet-history>
  );
}
